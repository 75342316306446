
import { Component, Vue, Prop } from "vue-property-decorator";
import store from "../../core/store";
import { initSwiper } from "../../js/initSwiper.js";

@Component
export default class Slider extends Vue {
  @Prop() data!: any;

  swiper!: any;
  mounted() {
    if (this.data !== undefined && this.data !== null) {
      this.swiper = initSwiper();
    }
  }

  nextSlide() {
    this.swiper.slideNext();
  }
  prevSlide() {
    this.swiper.slideNext();
  }

  getImageUrl(name: string) {
    return store.state.settings.Uploads + "/" + name;
  }
}
