
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import InputPassword from "../components/form/inputPassword.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";
import { LocalStorage } from "../core/localStorage";

@Component({
  components: {
    InputPassword,
    SubmitButton,
  },
})
export default class ChangePasswordPage extends Vue {
  ok: string = "";
  currentPass: string = "";
  newPass: string = "";
  errorCurrent: string = "";
  errorNew: string = "";

  async save(e: any) {
    e.preventDefault();

    this.ok = "";
    this.errorNew = "";
    this.errorCurrent = "";

    if (
      this.currentPass === "" ||
      this.currentPass === undefined ||
      this.currentPass === null
    ) {
      this.errorCurrent = this.$t("Required").toString();
    }
    if (
      this.newPass === "" ||
      this.newPass === undefined ||
      this.newPass === null
    ) {
      this.errorNew = this.$t("Required").toString();
    }

    if (this.newPass.length < 5) {
      this.errorNew += " " + this.$t("Minimun length is 5").toString();
    }

    if (this.errorNew !== "" || this.errorCurrent !== "") {
      return;
    }

    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    let url = `${Settings.HostName}/api/publiccustomers/reset-password-by-old-json`;

    const body = {
      customerId: cid,
      plainOldPass: this.currentPass,
      plainNewPass: this.newPass,
    };
    let data = await new CrudService().httpPost(url, body);

    if (data == null || data === undefined || data.ok === false) {
      this.errorCurrent = this.$t("Wrong password").toString();
      return;
    }
    this.errorCurrent = "";
    this.errorNew = "";
    this.ok = this.$t("Data modified!").toString();
  }
}
