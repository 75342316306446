
import { Component, Vue, Prop } from "vue-property-decorator";
import { Format } from "../../core/helpers/formatHelper";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import { ShopService } from "../../core/services/shopService";
import store from "../../core/store";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class PopularProducts extends Vue {
  @Prop() title!: string;
  slides: any[] = [];
  carouselSettings: any = null;

  async mounted() {
    let url = `${Settings.HostName}/api/products/popular-products`;
    let data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash getting trending products");
      return;
    }
    this.slides = data.data;
    this.carouselSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "ease-in-out",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        }
      ],
    };
  }

  formatCurrency(number: number) {
    if (number === undefined) {
      return "";
    }
    return Format.formatCurrency(number.toString());
  }

  getImageProduct(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  addToCart(p: any, e: any) {
    e.preventDefault();
    new ShopService().addProductToCart(p, 1, e.currentTarget);
  }
}
