
import Vue from "vue";
import Component from "vue-class-component";
import { Format } from "../core/helpers/formatHelper";
import { CustomerHelper } from "../crm/helpers/customerHelper";

@Component({
  components: {},
})
export default class ActivatePage extends Vue {
  responseMsg: string = "";
  passToken: string = "";

  async created() {
    this.passToken = this.$route.query.t;
    await this.validate();
  }

  async validate() {
    this.responseMsg = this.$t("Auto login").toString();

    if (this.passToken == null || this.passToken === undefined) {
      this.responseMsg = this.$t("Wrong access").toString();
      return;
    }
    let data: any = {};
    if (this.passToken.indexOf(".") !== -1) {
      // comming form emails
      const o = JSON.parse(this.passToken);
      data.data = {};
      data.data.model = Format.convertKeysToLowerCase([o])[0];
    } else {
      data = JSON.parse(atob(this.passToken));
    }

    if (Format.IsNull(data)) {
      this.responseMsg = this.$t("Wrong access").toString();
      return;
    }

    if (Format.IsNull(data.data)) {
      this.responseMsg = this.$t("Wrong access").toString();
      return;
    }
    CustomerHelper.logOut();

    CustomerHelper.saveLogedCustomer(
      data.data.model.token,
      data.data.model.expiry,
      data.data.model.id,
      data.data.model.logo,
      data.data.model.name,
      data.data.model.surname
    );
    const backUrl = Format.IsNull(this.$route.query.back)
      ? "/"
      : this.$route.query.back;

    setTimeout(() => {
      location.href = backUrl;
    }, 1000);
  }
}
