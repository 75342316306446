import { LocalStorage } from "../localStorage";
import store from "../store";
import { Loader } from "../../core/helpers/loaderHelper";
import { Format } from "../../core/helpers/formatHelper";
import { Global } from "../../core/helpers/global";

export class CrudService {

    async httpGet(url: string, body?: any, callback?: Function, requestContentType: string = "json") {
        return await this.httpFetch(url, "GET", body, callback, requestContentType);
    }

    async httpPost(url: string, body?: any, callback?: Function, requestContentType: string = "json") {
        return await this.httpFetch(url, "POST", body, callback, requestContentType);
    }

    async httpPut(url: string, body?: any, callback?: Function, requestContentType: string = "json") {
        return await this.httpFetch(url, "PUT", body, callback, requestContentType);
    }

    async httpDelete(url: string, body?: any, callback?: Function, requestContentType: string = "json") {
        return await this.httpFetch(url, "DELETE", body, callback, requestContentType);
    }

    async httpFetch(url: string, method: string, body?: any, callback?: Function, requestContentType: string = "json") {
        this.showLoader();
        const requestOptions = this.buildRequest(method, requestContentType, body);
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            const res = this.handleNon200Response(response, callback);
            this.hideLoader();
            return res;
        }

        const res = await this.handleResponse(response, callback);
        this.hideLoader();
        return res;
    }

    private showLoader() {
        if (Format.IsNull(Global.TriggerDomId)) {
            return;
        }

        Loader.Loading(Global.TriggerDomId);
    }

    private hideLoader() {
        if (Format.IsNull(Global.TriggerDomId)) {
            return;
        }
        Loader.Unloading(Global.TriggerDomId);
        Global.TriggerDomId = "";
    }


    private buildRequest(method: string, requestContentType: string, body?: any) {
        const token = this.getCustomerBearerTokenFromLocalStorage();
        const version = localStorage.getItem(window.location.host + "_czv");
        const requestOptions: any = {
            method: method,
            headers: new Headers({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
                'CzVersion': version !== null ? version : ''
            }),
            useCredentails: true,
            body: this.buildRequestBody(requestContentType, body)
        };
        if (requestContentType != "multipart") {
            const headers: Headers = requestOptions.headers;
            headers.append('Content-Type', this.getRequestContentType(requestContentType));
        }
        return requestOptions;
    }

    private getCustomerBearerTokenFromLocalStorage() {
        return LocalStorage.getWithTimeout("ctk");
    }

    private async handleResponse(response: Response, callback?: Function) {

        if (!this.checkVersion(response)) {
            if (callback) callback(false);
            return;
        }

        const responseContentType = response.headers.get("content-type");

        if (responseContentType && (
            responseContentType.includes("application/json") ||
            responseContentType.includes("application/problem+json"))) {

            try {
                const json = await response.json();

                if (json.errors && Object.keys(json.errors).length > 0) {
                    // Errors object has properties, so the error is likely to be specific for a field
                    if (callback) return callback(response.ok, json.errors);
                } else {
                    // Errors object does not exist ot is empty, so the error is likely to be a general one. In this 
                    // case we return the whole (parent) object, not only the object property, so we can have more
                    // info about the error in the caller
                    if (callback) return callback(response.ok, json);
                }

                return json;
            } catch (e) {
                console.error(e);
                if (callback) return callback(false);
            }

        } else if (responseContentType && responseContentType.includes("openxmlformats")) {

            const xlsx = await response.blob();
            const outputFilename = `${Date.now()}.xls`;
            const urlDownload = URL.createObjectURL(new Blob([xlsx]));
            const link = document.createElement("a");
            link.href = urlDownload;
            link.setAttribute("download", outputFilename);
            document.body.appendChild(link);
            link.click();

            return xlsx;
        } else if (responseContentType && responseContentType.includes("application/pdf")) {

            const fileName = response.headers.get("filename");
            const pdfBlob = await response.blob();
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.download = fileName ?? "file.pdf";
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(pdfUrl);

            if (callback) return callback(response.ok, pdfBlob);

            return pdfBlob;
        } else {
            console.warn(`Unmanaged response Content-Type=${responseContentType}, defaulting to text...`, response);

            const text = await response.text();
            if (callback) return callback(response.ok, text);

            return text;
        }
    }

    private handleNon200Response(response: Response, callback?: Function) {
        if (response.status === 401) {
            this.logout(callback);
            return response;
        }

        return this.handleResponse(response, callback);
    }

    private logout(callback?: Function) {
        localStorage.clear();
        if (callback) return callback(false);
        store.commit("openLogin", { backUrl: window.location.pathname });
    }

    private buildRequestBody(ct: string, body?: any) {
        if (!body) {
            return null;
        }
        if (body && ct == "json") {
            return JSON.stringify(body);
        }
        return body;
    }

    private getRequestContentType(ct: string) {
        if (ct == "json") return "application/json-patch+json";
        if (ct == "form") return "application/x-www-form-urlencoded";
        if (ct == "multipart") return "multipart/form-data";
        return "json";
    }

    private checkVersion(response: Response) : boolean {
        const vkey = "cz-storage-version";
        const storedVersion = Number(localStorage.getItem(vkey));
        const version = Number(response.headers.get("x-cz-storage-version"));
        // Logout when there is a version mismatch
        if(storedVersion !== version) {
            // Version 0 means the server just got restarted
            // so we don't want to logout the user
            if(version > 0) {
                this.logout();
            }
            localStorage.setItem(vkey, version.toString());
            return false;
        }
        return true;
    }
}












