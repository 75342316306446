
import { Component, Vue } from "vue-property-decorator";
import store from "../../../shared/core/store";
import { ShopService } from "../../../shared/core/services/shopService";
import { Format } from "../../core/helpers/formatHelper";
import { SaleItem } from "../../core/interfaces/saleItem";
import { ModifyMode } from "../../core/constants";

@Component
export default class menuShopCart extends Vue {
  shop: any = {};
  mounted() {
    this.shop = new ShopService();
  }

  increment(sItem: SaleItem) {
    this.shop.modifyUnits(ModifyMode.Increment, sItem, 1);
  }

  decrement(sItem: SaleItem) {
    this.shop.modifyUnits(ModifyMode.Decrement, sItem, 1);
  }

  modify(sItem: SaleItem) {
    const units = Number(sItem.units);
    if (isNaN(units)) {
      return;
    }
    this.shop.modifyUnits(ModifyMode.Exact, sItem, units);
  }

  removeSale(e: any, saleId: any) {
    e.preventDefault();
    this.shop.removeSaleFromCart(saleId);
  }

  getPrice(price: string) {
    return Format.formatCurrency(price);
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
    }
    return store.state.settings.Uploads + "/" + img;
  }

  continueShopping() {
    const page: any = document.getElementsByClassName("page")[0];
    if (page !== null && page !== undefined) {
      page.click(); // click outside to hide the menu
    }
    this.$router.push("/");
  }

  goToCheckout() {
    const page: any = document.getElementsByClassName("page")[0];
    if (page !== null && page !== undefined) {
      page.click(); // click outside to hide the menu
    }
    this.$router.push("/checkout");
    // to="/checkout"
  }
}
