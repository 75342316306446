import DrawType from "./DrawType";
const path1: DrawType = {
  scale: 2.5,
  zoom: 2,
  fontSize: 12,
  name: "",
  bk: { href: "" },
  paths: {
    1: {
      label: "cuadrado",
      type: "path",
      d: "M 0 0 l 30 0 l 0 30 l -30 0 z",
      id: 1
    },
    2: {
      label: "rectangulo",
      type: "path",
      d: "M 0 0 l 80 0 l 0 60 l -80 0 z",
      id: 2
    },
    3: {
      label: "circulo",
      type: "path",
      d:
        "M 0 0 a 30 30 0 0 1 30,-30 a 30 30 0 0 1 30,30 a 30 30 0 0 1 -30,30 a 30 30 0 0 1 -30,-30",
      id: 3
    },
    4: {
      label: "guide",
      type: "path",
      d: "M 0 0 a 60000 60000 0 0 1 600,0",
      o: "guide",
      id: 4
    }
  },
  data: [
    // { label: "A:1", ref: 1, trans: [100, 100], rot: 0 },
    // { label: "B:1", ref: 2, trans: [400, 300], rot: 0 },
    // { label: "C:1", ref: 3, trans: [600, 100], rot: 0 },
  ],
  guides: [
    // { label: "guia 1", ref: 4, trans: [610, 400], rot: 0 }
  ]
};

const pathsv: DrawType[] = [path1];
export class Datas {
  public paths = pathsv;
}
