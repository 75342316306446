
import { Component, Vue } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import store from "../../core/store";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class Sponsors extends Vue {
  images: any[] = [];
  data: any[] = [];
  carouselSettings: any = null;

  async mounted() {
    const url = `${Settings.HostName}/api/fileuploads/open-list?parentName=CZConfig&parentId=1&category=Sponsors`;
    const data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash getting cz gallery");
      return;
    }

    for (const image of data.data) {
      const img =
        store.state.settings.Uploads + "/CzConfig/Sponsors/" + image.url;
      this.images.push(img);
    }
    this.data = data.data;

    this.carouselSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      cssEase: "ease-in-out",
    };
  }
}
