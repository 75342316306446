
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Popover extends Vue {
  @Prop() tooltipText!: string;
  @Prop() positionOver!: string; // if not setted wil be displayed under
  @Prop() customStyle!: string;
}
