
import Vue from "../node_modules/vue";
import Component from "../node_modules/vue-class-component";
import VisualBooking from "../components/visualBooking/visualBooking.vue";

@Component({
  components: { VisualBooking },
})
export default class BookingVisualPage extends Vue {
  errorMsg: string = "";

  mounted() {}

  placeSelected(data: any) {
    if (data === null || data === undefined) {
      return;
    }
    return this.$router.push(
      "/bookings-byplace?bookingId=" +
        data.bookingId +
        "&placeId=" +
        data.placeId +
        "&place=" +
        data.place +
        "&date=" +
        this.$route.query.date
    );
  }
}
