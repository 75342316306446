import { Format } from "../../core/helpers/formatHelper";

export class Loader {

    /** Renders:
     *   <div id="loader_btnAvailability" class="d-b">
     *      <div class="spinnerButton bgc-teal-fortia"></div>
     *   </div>
     */
    static Loading(id: string | null | undefined, css: any="d-b") {
        if (Format.IsNull(id)) {
            return;
        }
        const input = document.getElementById(id as string);
        if (input === null || input === undefined) {
            return;
        }

        if (input.parentNode === null) {
            return;
        }

        const divLoader = document.getElementById('loader_' + id);
        if (divLoader !== null) {
            return;
        }

        const div = document.createElement("div");
        div.id = "loader_" + id;
        div.className = input.getAttribute("class") as string;
        if (!Format.IsNull(css)) {
            div.classList.add(css);
        }
        const spinner = document.createElement("div");
        div.appendChild(spinner);
        spinner.classList.add("spinnerButton");
        spinner.classList.add("background-main-color");

        //Append at same index
        const parent = (input as HTMLElement).parentElement as HTMLElement;
        const index = Array.from(
            parent.children
          ).indexOf(input);

        input.parentNode.insertBefore(div, parent.children[index]);
        input.classList.add("d-n");

    }

    static Unloading(id: string | null | undefined) {
        if (Format.IsNull(id)) {
            return;
        }
        const input = document.getElementById(id as string);
        if (input === null || input === undefined) {
            return;
        }

        const divLoader = document.getElementById('loader_' + id);
        if (divLoader === null || divLoader === undefined) {
            return;
        }

        input.className = divLoader.getAttribute("class") as string;
        input.classList.remove("d-n");

        const parentNode = divLoader.parentNode as Node;
        parentNode.removeChild(divLoader);
    }
}
