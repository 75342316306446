
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";

@Component({
  components: {},
})
export default class MyCommsPage extends Vue {
  data: any = null;
  errorMsg: string = "";

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publiccomms/customer-comms/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      this.errorMsg = data.errorMsg;
      console.error("Crash getting my comms");
      return;
    }

    this.data = data.model;
  }

  showEmail(id: any) {
    this.$router.push(`/customer-comms/emails/${id}`);
  }
}
