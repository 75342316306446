export class Calcs {
	public dec(n: number, d: number): number {
		const c = Math.pow(10, d);
		return Math.round(c * n) / c;
	}

	public snap(n: number, d: number): number {
		return Math.round(n / d) * d;
	}

	public sign(n: number): number {
		let nn = 1;
		if (n < 0) {
			nn = -1;
		}
		if (n === 0) {
			nn = 0;
		}
		return nn;
	}

	public arcDirection(
		hx1: number,
		hy1: number,
		dx1: number,
		dy1: number,
		arcdire: string
	) {
		let sgn = 1;
		if (arcdire === "0") {
			sgn = -1;
		}

		const hx2 = hx1 - dx1;
		const hy2 = hy1 - dy1;

		const inv = this.arcRevert(hx1, hy1, hx2, hy2);

		const sgn3 = sgn * inv;
		if (sgn3 === -1) {
			arcdire = "0";
		}
		if (sgn3 === 1) {
			arcdire = "1";
		}
		return arcdire;
	}

	public arcRevert(hx1: number, hy1: number, hx2: number, hy2: number) {
		const sx1 = this.sign(hx1);
		const sy1 = this.sign(hy1);
		const sx2 = this.sign(hx2);
		const sy2 = this.sign(hy2);
		let inv = -1;

		if (sx1 === -1 && sy1 === 1 && sx2 === -1 && sy2 === 1) {
			inv = 1;
		}
		if (sx1 === -1 && sy1 === -1 && sx2 === -1 && sy2 === -1) {
			inv = 1;
		}

		if (sx1 === 1 && sy1 === 1 && sx2 === 1 && sy2 === 1) {
			inv = 1;
		}
		if (sx1 === 1 && sy1 === -1 && sx2 === 1 && sy2 === -1) {
			inv = 1;
		}

		if (sx1 === 0) {
			if (sy1 === 1 && sy2 === 1) {
				inv = 1;
			}
			if (sy1 === -1 && sy2 === -1) {
				inv = 1;
			}
		}

		if (sy1 === 0) {
			if (sx1 === 1 && sx2 === 1) {
				inv = 1;
			}
			if (sx1 === -1 && sx2 === -1) {
				inv = 1;
			}
		}
		return inv;
	}

	public ratioArc(
		dx: number,
		dy: number,
		hx1: number,
		hy1: number,
		dx1: number,
		dy1: number
	) {
		const d = Math.hypot(dx, dy);

		const hx2 = hx1 - dx1;
		const hy2 = hy1 - dy1;

		const h2 = Math.hypot(hx2, hy2);

		const d2 = d / 2;
		const an2 = Math.atan(Math.abs(h2) / d2);
		const beta = 2 * an2;
		const r = Math.abs(d2 / Math.sin(beta));

		return r;
	}

	public InsidePolygon($polygon: any, $p: any) {
		let $counter = 0;

		let $p1: any = [];
		let $p2: any = [];

		const $n = $polygon.length;

		$p1 = $polygon[0];
		let $xinters: number;
		let $cc1: number;
		let $cc2: number;

		for (let $i = 0; $i < $n; $i++) {
			$p2 = $polygon[$i % $n];

			if ($p.y > this.getMin($p1.y, $p2.y)) {
				if ($p.y <= this.getMax($p1.y, $p2.y)) {
					if ($p.x <= this.getMax($p1.x, $p2.x)) {
						if ($p1.y !== $p2.y) {
							$xinters =
								(($p.y - $p1.y) * ($p2.x - $p1.x)) / ($p2.y - $p1.y) + $p1.x;

							$cc1 = ($p.y - $p1.y) * ($p2.x - $p1.x);
							$cc2 = $p2.y - $p1.y;
							$xinters = $cc1 / $cc2 + $p1.x;

							if ($p1.x === $p2.x || $p.x <= $xinters) {
								$counter++;
							}
						}
					}
				}
			}
			$p1 = $p2;
		}

		if ($counter % 2 === 0) {
			return 0;
		} else {
			return 1;
		}
	}

	private getMin($a: number, $b: number) {
		let $c: number;
		if ($a < $b) {
			$c = $a;
		} else {
			$c = $b;
		}
		return $c;
	}

	private getMax($a: number, $b: number) {
		let $c: number;
		if ($a > $b) {
			$c = $a;
		} else {
			$c = $b;
		}
		return $c;
	}
}
