
import { Component, Vue, Prop } from "vue-property-decorator";
import Popup from "../../components/popup/popup.vue";
import { Format } from "../../core/helpers/formatHelper";

@Component({
  components: { Popup },
})
export default class CancelReturnsInfoCz extends Vue {
  @Prop() infoReturns!: any[];

  showModal: boolean = false;

  mounted() {
    this.showModal =
      this.infoReturns !== undefined &&
      this.infoReturns !== null &&
      this.infoReturns.length > 0;
  }

  formatAmount(amount: any) {
    if (amount == null) {
      return Format.formatCurrency("0");
    }
    return Format.formatCurrency(amount as string);
  }
}
