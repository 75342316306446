
import { CrudService } from "../../core/services/crudService";
import { Settings, TenantSettings } from "../../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../../core/localStorage";
import { Format } from "../../core/helpers/formatHelper";
import store from "../../core/store";
import InputText from "../../components/form/inputText.vue";

@Component({
  components: { InputText },
})
export default class MenuSearch extends Vue {
  settings: TenantSettings = {};
  products: any = null;
  search: string = "";
  totalCount: number = 0;
  gridStatus: string = "";

  async mounted() {
    this.settings = store.state.settings;
  }

  async loadData() {
    let cid = null;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    let filters: any = {};
    let searchFilter: any = {};
    let sort: any = [];
    sort = [{ colId: "Created", sort: "desc" }];

    if (this.search !== null && this.search !== "") {
      searchFilter["Name"] = {
        condition1: {
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition2: {
          secondField: "Detail",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition3: {
          secondField: "Description",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        operator: "OR",
      };

      filters = Object.assign(filters, searchFilter);
    }

    this.gridStatus = JSON.stringify({ filters: filters, sort: sort });
    this.gridStatus = encodeURIComponent(this.gridStatus);

    let url =
      cid == null
        ? `${Settings.HostName}/api/shop/list?startRow=0&endRow=1000&gridStatus=${this.gridStatus}`
        : `${Settings.HostName}/api/shop/list-by-customer?customerId=${cid}&startRow=0&endRow=1000&gridStatus=${this.gridStatus}`;
    let data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting shop " + cid);
      return;
    }

    this.products = data.data;
    this.totalCount = data.totalCount;
    window.scrollTo(0, 0);
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  async selectSearch() {
    if (this.search === "") {
      this.products = null;
      return;
    }
    if (this.search.length > 3) {
      await this.loadData();
    }
  }

  clearSearch() {
    this.search = "";
    this.products = null;
  }

  closeForm() {
    const dom: any = this.$refs.toggleButton;
    const domInput: any = this.$refs.toggleDiv;
    if (dom.classList.contains("active")) {
      dom.classList.remove("active");
      domInput.classList.remove("active");
    }

    this.clearSearch();
  }

  getLabel(item: any) {
    return Format.highLightText(item, this.search);
  }

  goToShop(e: any, id: any) {
    e.preventDefault();
    let shopPageSearch = this.search;
    this.closeForm();

    if (id === null || id === undefined || shopPageSearch === "") {
      this.$router.push("/shop");

      this.$router.push({
        name: "shop",
        query: {
          search: shopPageSearch,
        },
      });

      return;
    }

    this.$router.push(`/shop/${id}`);
  }
}
