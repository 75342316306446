
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import { Format } from "../core/helpers/formatHelper";
import Vue from "vue";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";

interface OrderItem {
  name: string;
  productId: number;
  category: number;
  logo: string|null;
  units: number;
  paymentMethod: string|null;
  price: number;
  total: number;
}

interface Order {
  id: number;
  date: string;
  number: string;
  online: string;
  paymentMethod: string;
  items: OrderItem[];
  subTotal: number;
  total: number;
  discount: number;
  shipping: number;
}

@Component({
  components: {
    CancelReturnsInfo,
  },
})
export default class MyOrdersPage extends Vue {
  data: Order[]|null = null;
  errorMsg: string = "";
  infoReturns: any = null;
  reload: number = 1;
  settings: TenantSettings = {};

  async mounted() {
    this.settings = store.state.settings;
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/shop/customer-orders/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      console.error("Crash getting Your Orders");
      return;
    }

    this.data = data.model;
  }

  getImagePath(img: string|null) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string|number) {
    return Format.formatCurrency(price);
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt)).split(" ")[0];
  }
}
