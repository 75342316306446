
import { CrudService } from "../../../core/services/crudService";
import { Settings } from "../../../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../../../core/helpers/dateHelper";
import store from "../../../core/store";
import PopularPosts from "../../../components/popularPosts/popularPosts.vue";
import { CustomerHelper } from "../../helpers/customerHelper";
import { ParentNameActivity } from "../../../crm/constants";
import LoginForm from "../../../components/login/loginForm.vue";
import RegisterForm from "../../../components/login/registerForm.vue";
import Popup from "../../../components/popup/popup.vue";
import DynamicForm from "../../../components/dynamicForm/dynamicForm.vue";
import { ShopService } from "../../../core/services/shopService";
import { Format } from "../../../core/helpers/formatHelper";
import { LocalStorage } from "../../../core/localStorage";

@Component({
  components: { PopularPosts, LoginForm, RegisterForm, Popup, DynamicForm },
})
export default class ActivityPage extends Vue {
  activity: any = {};
  error: string = "";
  ok: string = "";
  showDynamicForm: boolean = false;
  showRegister: boolean = false;
  showLogin: boolean = false;
  hasCustomSignUp: boolean = false;
  showLoginRegister: boolean = false;
  customerId: number | null = null;
  alreadyIn: boolean = false;

  async mounted() {
    await this.loadData();
    window.scrollTo({ top: 0 });
  }

  async loadData() {
    let cid = CustomerHelper.getStoredCustomer();
    const url =
      cid !== undefined
        ? `${Settings.HostName}/api/publicactivities/${this.$route.params.id}/${cid}`
        : `${Settings.HostName}/api/publicactivities/${this.$route.params.id}`;

    let data = await new CrudService().httpGet(url);
    if (data == null) {
      this.error = this.$t("Crash").toString();
      return;
    }
    this.activity = data.model;
    this.hasCustomSignUp = data.hasCustomSignUp;
    if (data.alreadyIn === true) {
      this.ok = this.$t("Already In!").toString();
    }
  }

  singUpClicked() {
    if (this.ok !== "") {
      return;
    }
    this.error = "";
    let cid = CustomerHelper.getStoredCustomer();
    if (cid === undefined) {
      this.showLoginRegister = true;
      return;
    }
    this.customerId = cid;
    this.onCustomerSetted();
  }

  registerDone(data: any) {
    if (data === undefined) {
      return;
    }
    if (data.ok === true) {
      this.customerId = data.id;
      this.tempLogin();
      this.onCustomerSetted();
    }
  }

  loginDone(data: any) {
    if (data === undefined) {
      return;
    }
    if (data.ok === true) {
      this.customerId = data.id;
      this.onCustomerSetted();
    }
  }

  onCustomerSetted() {
    this.showLoginRegister = false;
    this.showLogin = false;
    this.showRegister = false;

    if (this.hasCustomSignUp === false) {
      this.directInscription();
      return;
    }
    this.showDynamicForm = true;
  }

  async directInscription() {
    const url = `${Settings.HostName}/api/publicactivities/inscription`;
    let res = await new CrudService().httpPost(url, {
      customerId: this.customerId,
      id: this.$route.params.id,
    });
    if (res == null) {
      this.error = this.$t("Crash").toString();
      return;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      return;
    }

    if (Format.IsNull(res.model)) {
      this.ok = this.$t("Sign Up Done!").toString();
      return;
    }
    this.addToCart(res.model);
  }

  dynamicFormSaved(data) {
    if (!data.success === true) {
      return;
    }

    if (Format.IsNull(data.model) || typeof data.model == "number") {
      this.showDynamicForm = false;
      this.ok = this.$t("Sign Up Done!").toString();
      return;
    }

    this.addToCart(data.model);
  }

  addToCart(sale: any) {
    if (sale.ctk !== null) {
      let in5Mins: any = DateHelper.addMinutes(new Date(), 5);
      LocalStorage.addWithTimeout("ctk", sale.ctk, in5Mins);
      store.state.loggedIn = true;
    }

    sale.title = sale.description;
    new ShopService().addSaleToCart(sale, document.getElementById("btnSignUp"));
    this.$router.push("/checkout");
  }

  tempLogin() {
    let in5Mins: any = DateHelper.addMinutes(new Date(), 5);
    LocalStorage.addWithTimeout(
      "cc",
      JSON.stringify({ id: this.customerId }),
      in5Mins
    );
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }

  getParent() {
    return ParentNameActivity;
  }

  dynamicFormLoaded(hasData: boolean) {
    this.hasCustomSignUp = hasData;
  }
}
