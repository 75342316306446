import { CrudService } from "./crudService";
import { Settings } from "./configService";

export class InvoiceService {
    crudService: CrudService = new CrudService();
    basePath: string = `${Settings.HostName}/api`;

    async downloadInvoicePdf(token: string, callback?: Function) {
        const url: string = `${this.basePath}/publicinvoices/download-invoice/${token}`;
        await this.crudService.httpGet(url, null, callback);
    }
}