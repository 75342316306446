
import { Component, Vue, Prop } from "vue-property-decorator";
import { Format } from "../../../core/helpers/formatHelper";
import { Settings } from "../../../core/services/configService";
import { CrudService } from "../../../core/services/crudService";
import store from "../../../core/store";
import { DateHelper } from "../../../core/helpers/dateHelper";

@Component
export default class ActivitiesBanner extends Vue {
  @Prop() title!: string;
  activities: any[] = [];

  async mounted() {
    let url = `${Settings.HostName}/api/publicactivities/list`;
    let data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash");
      return;
    }
    this.activities = data.model;
  }

  formatCurrency(number: number) {
    if (number === undefined) {
      return "";
    }
    return Format.formatCurrency(number.toString());
  }

  formatDate(date: string) {
    return DateHelper.toCultureString(date);
  }

  getImageProduct(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return "/img/activity-default.png";
  }
}
