
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { DateHelper } from "../core/helpers/dateHelper";
import { BookingPaymentType, ModifyMode } from "../core/constants";
import { ShopService } from "../core/services/shopService";
import InputText from "../components/form/inputText.vue";
import InputPassword from "../components/form/inputPassword.vue";
import CreditCardForm from "../components/form/creditCardForm.vue";
import { SaleItem } from "../core/interfaces/saleItem";
import Checkbox from "../components/form/checkBox.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import CheckoutPayment from "./checkoutPayment.vue";
import { SharedPaymentsService } from "../core/services/sharedPaymentsService";

@Component({
  components: {
    InputText,
    InputPassword,
    Checkbox,
    CreditCardForm,
    SubmitButton,
    TriggerAnchor,
    CheckoutPayment,
  },
})
export default class CheckoutPage extends Vue {
  settings: TenantSettings = {};
  error: string = "";
  data: any = null;
  transport: any = null;
  errors: any = {};
  paymentMethodId: number | null = null;
  reloadErrors: number = 0;
  cid: number | null = null;
  shopSvc: ShopService = new ShopService();

  async mounted() {
    let payload: any = {};

    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      this.cid = JSON.parse(sid).id;
    }

    this.settings = this.$store.state.settings;
    if (
      this.$store.state.cart === null ||
      this.$store.state.cart === undefined
    ) {
      return this.$router.push("/");
    }

    if (this.$store.state.cart.count === 0) {
      return this.$router.push("/");
    }

    let i = 0;
    for (const saleItem of this.$store.state.cart.sItems) {
      saleItem.saleId = --i; // saleId tmp to identify
    }

    payload = {
      customerId: this.cid,
      saleItems: this.$store.state.cart.sItems,
      saleIds: this.$store.state.cart.sales.map((y) => y.saleIds).flat(),
    };

    let url =
      this.cid == null
        ? `${Settings.HostName}/api/shop/checkout-info`
        : `${Settings.HostName}/api/shop/checkout-info-by-customer`;

    let res = await new CrudService().httpPost(url, payload);
    if (res == null || res === undefined) {
      console.error("Crash getting checkout info " + this.cid);
      if (res.ok === false) {
        console.error(res.errorMsg);
      }
      return;
    }

    const data = res.data;
    if (data === null) {
      console.error(res.errorMsg);
      return;
    }

    this.data = data;

    const sale = this.$store.state.cart.sales[0];
    let isSharedPayment =
      sale && sale.paymentType === BookingPaymentType.Shared;

    if (isSharedPayment) {
      const svc = new SharedPaymentsService();
      const pm = this.data.paymentMethods;
      this.data.paymentMethods = pm.filter((y: any) => svc.isAllowed(y.type));
      this.data.vouchers = [];
      this.data.isSharedPayment = true;
      this.data.paymentDeadline = DateHelper.beautifyDate(sale.paymentDeadline);
    }

    if (
      this.data.transports !== null &&
      this.data.transports !== undefined &&
      this.data.transports.length > 0
    ) {
      this.transportRowClick(this.data.transports[0]);
    }
  }

  removeSaleFromCart(e: any, saleId: number) {
    e.preventDefault();
    this.shopSvc.removeSaleFromCart(saleId);
  }

  removeSaleItemFromCart(e: any, saleItem: any) {
    e.preventDefault();
    this.shopSvc.removeSaleItemFromCart(saleItem);
  }

  incrementUnits(sItem: SaleItem) {
    this.shopSvc.modifyUnits(ModifyMode.Increment, sItem, 1);
  }

  decrementUnits(sItem: SaleItem) {
    this.shopSvc.modifyUnits(ModifyMode.Decrement, sItem, 1);
  }

  modifyUnits(sItem: SaleItem) {
    const units = Number(sItem.units);
    if (isNaN(units)) {
      return;
    }
    this.shopSvc.modifyUnits(ModifyMode.Exact, sItem, units);
  }

  showDeliveryInfo() {
    return (
      !this.$store.state.loggedIn ||
      (this.data !== null &&
        this.data.transports !== null &&
        this.$store.state.cart.sItems !== undefined &&
        this.$store.state.cart.sItems !== null &&
        this.$store.state.cart.sItems.length != 0)
    );
  }

  checkTransportRequired() {
    return this.$store.state.cart.sItems.some(
      (y) => y.units > 0 && y.product.transportRequired === true
    );
  }

  getImagePath(img: string | null) {
    if (!img) {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  transportRowClick(transport: any) {
    const dom = document.getElementById("trans_" + transport.id);
    if (dom !== null && dom !== undefined) dom.click();
    this.transport = transport;
  }
}
