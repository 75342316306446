var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{staticClass:"breadcrumbs-custom-inset"},[_c('div',{staticClass:"breadcrumbs-custom context-dark"},[_c('div',{staticClass:"container"}),_c('div',{staticClass:"box-position",style:(`background-image: url('${_vm.$store.state.settings.HeaderImage}')`)})])]),(_vm.booking !== null)?_c('section',{staticClass:"section novi-bg novi-bg-img section-xl bg-default checkout-booking"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-40"},[_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('article',{staticClass:"box-icon-modern rounded-2xl neomorph-style"},[_c('BookingSummary',{attrs:{"booking":_vm.booking}})],1)]),_c('div',{staticClass:"col-md-6 col-lg-6"},[_c('article',{staticClass:"box-icon-modern rounded-2xl neomorph-style"},[_c('h5',{staticClass:"box-icon-modern-title font-medium leading-6 tracking-normal text-left"},[_vm._v(" "+_vm._s(_vm.$t("PAY MODE"))+" ")]),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"backgroundPayment md:mt-8 sm:mt-4 sm:pr-3"},[(_vm.allowSharedPayment)?_c('div',{staticClass:"paymentOption",class:{
                    'neomorph-style selected':
                      _vm.paymentType === _vm.BookingPaymentType.Shared,
                  }},[_c('div',{staticClass:"cursor-pointer flex items-center justify-between sm:flex-col sm:justify sm:relative pl-3 mt-3 pb-4",on:{"click":function($event){_vm.paymentType = _vm.BookingPaymentType.Shared}}},[_c('div',{staticClass:"flex text-left"},[_c('div',{},[_c('span',{staticClass:"mb-2 text-xl flex items-center"},[_c('div',{staticClass:"custom-check rounded-lg flex justify-center items-center w-11 h-8 mr-2",class:{
                              'background-main-color':
                                _vm.paymentType === _vm.BookingPaymentType.Shared,
                            }},[(_vm.paymentType === _vm.BookingPaymentType.Shared)?_c('img',{attrs:{"src":require("../../padel/src/images/check.png"),"alt":"Check"}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.$t("You Pay Your Part"))+" ")]),_c('p',{staticClass:"text-sm mt-3 mr-3"},[_vm._v(" "+_vm._s(_vm.$t("The payment deadline is"))+" "),_c('span',{},[_vm._v(" "+_vm._s(_vm.getPaymentDeadline()))])])])]),_c('div',{staticClass:"flex flex-col sm:flex-row sm:items-baseline sm:mt-3"},[_c('div',{staticClass:"pb-4 mr-3"},[_c('p',{staticClass:"text-2xl text-right",class:{
                            'font-semibold':
                              _vm.paymentType === _vm.BookingPaymentType.Shared,
                          }},[_vm._v(" "+_vm._s(_vm.getSharedStrPrice())+" ")])]),_c('div',{staticClass:"p-3 flex flex-row justify-between items-center neomorph-style mr-3 sm:items-end",on:{"click":function($event){_vm.addPlayerPopup = true}}},[_c('p',{staticClass:"text-sm text-center addPlayersText"},[_vm._v(" "+_vm._s(_vm.$t("Add players"))+" ")]),_c('div',{staticClass:"playerIcon pl-3 relative fa-user"})])])])]):_vm._e(),(_vm.allowSharedPayment)?_c('div',{staticClass:"containerPayPlayers mr-2 neomorph-style rounded-t-none"},_vm._l((_vm.players),function(player,index){return _c('div',{key:index,staticClass:"checkoutPlayers"},[_c('div',{staticClass:"flex items-center justify-start px-3 py-1.5"},[(player.logo)?_c('img',{staticClass:"sm:w-8 sm:h-8 mr-3",attrs:{"src":_vm.getImagePath(player.logo),"alt":""}}):_vm._e(),_c('div',{staticClass:"w-50 text-left"},[_c('div',{staticClass:"sm:text-xs truncate"},[_vm._v(" "+_vm._s(player.name || _vm.$t("Free Slot"))+" ")])]),_c('div',{staticClass:"w-1/5 text-right font-semibold"},[_vm._v(" "+_vm._s(_vm.formatPrice(player.price))+" ")]),_c('div',{staticClass:"flex-none w-12 pl-3 ml-auto"},[_c('div',{staticClass:"trashIcon fa-trash cursor-pointer main-color bg-transparent",style:({
                            display:
                              _vm.owner.id !== player.customerId
                                ? 'block'
                                : 'none',
                          }),on:{"click":function($event){return _vm.removePlayer(player)}}})])])])}),0):_vm._e(),(_vm.allowFullPayment)?_c('div',{staticClass:"paymentOption cursor-pointer flex md:items-center md:flex-row p-6 justify-between md:mt-8 sm:mt-4 sm:flex-col",class:{
                    'neomorph-style selected':
                      _vm.paymentType === _vm.BookingPaymentType.Full,
                  },on:{"click":function($event){_vm.paymentType = _vm.BookingPaymentType.Full}}},[_c('div',{staticClass:"flex text-left sm:w-full"},[_c('div',{staticClass:"w-44 sm:w-full"},[_c('h2',{staticClass:"mb-2 text-xl flex items-center"},[_c('div',{staticClass:"custom-check rounded-lg flex justify-center items-center w-11 h-8 mr-2",class:{
                            'background-main-color':
                              _vm.paymentType === _vm.BookingPaymentType.Full,
                          }},[(_vm.paymentType === _vm.BookingPaymentType.Full)?_c('img',{attrs:{"src":require("../../padel/src/images/check.png"),"alt":"Check"}}):_vm._e()]),_vm._v(" "+_vm._s(_vm.$t("Full Payment"))+" ")]),_c('p',{staticClass:"text-sm pr-2 sm:mb-4"},[_vm._v(" "+_vm._s(_vm.$t("You assume"))),_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("the full booking")))])])])]),_c('div',{staticClass:"sm:w-full"},[_c('p',{staticClass:"text-2xl",class:{
                        'font-semibold':
                          _vm.paymentType !== _vm.BookingPaymentType.Shared,
                      }},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.bookingPrice.toString()))+" ")])])]):_vm._e(),_c('div',{staticClass:"flex justify-center md:mt-8 sm:mt-4"},[_c('TriggerAnchor',{staticClass:"button button-secondary button-pipaluk",attrs:{"id":"checkoutBtn","label":_vm.getTotalLabel()},on:{"click":function($event){return _vm.goToPay($event)}}})],1),(_vm.error != null && _vm.error != '')?_c('div',{staticClass:"warning",attrs:{"id":"errorDiv"},domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()])])])])])])]):_vm._e(),(_vm.addPlayerPopup)?_c('Popup',{attrs:{"showOkButton":false},on:{"closed":function($event){_vm.addPlayerPopup = false}}},[_c('div',{staticClass:"card-login-register login-form-width",attrs:{"slot":"body","id":"card-l-r"},slot:"body"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("Search a player"))+" "),_c('LookUp',{staticClass:"mt-3",on:{"selected":_vm.addPlayer}})],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }