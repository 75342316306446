
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import { Format } from "../core/helpers/formatHelper";
import Vue from "vue";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import CancelReturnsInfo from "../components/cancelReturnsInfoCz/cancelReturnsInfoCz.vue";
import { ProductCategory } from "@shared/core/constants";

@Component({
  components: {
    CancelReturnsInfo,
  },
})
export default class MyOrderPage extends Vue {
  order: any = null;
  saleId: any = null;
  errorMsg: string = "";
  infoReturns: any = null;
  reload: number = 1;
  settings: TenantSettings = {};

  async mounted() {
    this.settings = store.state.settings;
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    this.saleId = this.$route.params.id;
    let url = `${Settings.HostName}/api/shop/customer-orders/${customerId}/${this.saleId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      console.error("Crash getting Your Orders");
      return;
    }

    this.order = data.model;
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  getProductUrl(item: any) {
    if (item === null || item === undefined || item.productId === null || item?.category !== ProductCategory.Article) {
      return "";
    }
    return "/shop/" + item.productId;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt)).split(" ")[0];
  }
}
