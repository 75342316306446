
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import { ShopService } from "../core/services/shopService";
import { SortType } from "../core/constants";
import store from "../core/store";
import { Watch } from "vue-property-decorator";
import InputText from "../components/form/inputText.vue";

@Component({
  components: { InputText },
})
export default class ShopPage extends Vue {
  settings: TenantSettings = {};
  products: any = null;
  popular: any = null;
  productTypes: any = null;
  error: string = "";
  ok: string = "";
  productTypeId: number | null = null;
  search: string = "";
  sort: any[] = [];
  sortType: SortType = SortType.ByNew;
  firstRecord: number = 0;
  lastRecord: number = 0;
  pageSize: number = 30;
  totalCount: number = 0;
  paginationClass: string = "page-item page-item-control";
  paginationClassDisabled: string = "page-item page-item-control disabled";
  numPages: number = 4;
  reloadShop: number = 0;

  async mounted() {
    this.settings = store.state.settings;
    this.sort = [
      { value: 1, label: this.$t("Sort By New") },
      { value: 2, label: this.$t("Sort By Price Ascending") },
      { value: 3, label: this.$t("Sort By Price Descending") },
    ];
    this.lastRecord = this.pageSize;

    let initialSearch =
      this.$route.query === undefined || this.$route.query.search === undefined
        ? null
        : this.$route.query.search;

    await this.loadData(initialSearch);
  }

  async loadData(initialSearch: any = null) {
    let cid = null;
    let sid = LocalStorage.getWithTimeout("cc");
    if (sid !== undefined && sid !== null) {
      cid = JSON.parse(sid).id;
    }

    let filters: any = {};
    let productTypeFilter: any = {};
    let searchFilter: any = {};
    let sort: any = [];

    if (this.productTypeId !== null) {
      productTypeFilter["ProductTypeId"] = {
        type: "equals",
        filter: this.productTypeId,
        filterType: "number",
      };

      filters = Object.assign(filters, productTypeFilter);
    }

    if (this.sortType !== null) {
      switch (Number(this.sortType)) {
        case SortType.ByNew:
          sort = [{ colId: "Created", sort: "desc" }];
          break;
        case SortType.ByPriceAsc:
          sort = [{ colId: "GrossPrice", sort: "asc" }];
          break;
        case SortType.ByPriceDesc:
          sort = [{ colId: "GrossPrice", sort: "desc" }];
          break;
      }
    }

    this.search =
      initialSearch !== null && initialSearch !== undefined
        ? initialSearch
        : this.search;
    if (this.search !== null && this.search !== "") {
      searchFilter["Name"] = {
        condition1: {
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition2: {
          secondField: "Detail",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        condition3: {
          secondField: "Description",
          type: "contains",
          filter: this.search,
          filterType: "text",
        },
        operator: "OR",
      };

      filters = Object.assign(filters, searchFilter);
    }

    let gridStatus = encodeURIComponent(
      JSON.stringify({ filters: filters, sort: sort })
    );

    let url =
      cid == null
        ? `${Settings.HostName}/api/shop/list?startRow=${this.firstRecord}&endRow=${this.lastRecord}&gridStatus=${gridStatus}`
        : `${Settings.HostName}/api/shop/list-by-customer?customerId=${cid}&startRow=${this.firstRecord}&endRow=${this.lastRecord}&gridStatus=${gridStatus}`;
    let data = await new CrudService().httpGet(url);

    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting shop " + cid);
      return;
    }

    this.products = data.data;
    this.popular = data.popular;
    this.productTypes = data.productTypes;
    this.totalCount = data.totalCount;
    if (this.lastRecord >= this.totalCount) {
      this.lastRecord = this.totalCount;
    }
    window.scrollTo(0, 0);
  }

  getpaginationClassPrev() {
    return this.firstRecord <= 0
      ? this.paginationClassDisabled
      : this.paginationClass;
  }

  getpaginationClassNext() {
    return this.lastRecord >= this.totalCount
      ? this.paginationClassDisabled
      : this.paginationClass;
  }

  getItemListClass(index: number) {
    return index == 1
      ? "page-item pointer li-margin active"
      : "page-item pointer li-margin";
  }

  nextPage() {
    this.firstRecord += this.pageSize;
    this.lastRecord =
      this.firstRecord + this.pageSize >= this.totalCount
        ? this.totalCount
        : this.firstRecord + this.pageSize;
    this.loadData();
  }

  navigateTo(index: number) {
    let page = this.firstRecord / this.pageSize + (index - 1);
    this.firstRecord = page * this.pageSize;
    this.lastRecord =
      this.firstRecord + this.pageSize >= this.totalCount
        ? this.totalCount
        : this.firstRecord + this.pageSize;
    this.loadData();
  }

  previousPage() {
    this.firstRecord -= this.pageSize;
    this.lastRecord = this.firstRecord + this.pageSize;
    this.loadData();
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  async addToCart(p: any, e: any) {
    e.preventDefault();
    await new ShopService().addProductToCart(p, 1, e.currentTarget);
  }

  async selectProductType(id: number | null) {
    this.productTypeId = id;
    this.firstRecord = 0;
    this.lastRecord = this.pageSize;
    await this.loadData();
  }

  async selectSort() {
    this.firstRecord = 0;
    this.lastRecord = this.pageSize;
    await this.loadData();
  }

  async selectSearch() {
    if (this.search === "" || this.search.length > 3) {
      this.firstRecord = 0;
      this.lastRecord = this.pageSize;
      await this.loadData();
    }
  }

  @Watch("$route.query.search")
  async reload() {
    this.lastRecord = this.pageSize;
    let initialSearch =
      this.$route.query === undefined || this.$route.query.search === undefined
        ? null
        : this.$route.query.search;
    await this.loadData(initialSearch);
    this.reloadShop++;
  }
}
