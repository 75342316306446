
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { DateHelper } from "../core/helpers/dateHelper";
import store from "../core/store";
import PopularPosts from "../../shared/components/popularPosts/popularPosts.vue";

@Component({
  components: { PopularPosts },
})
export default class PostPage extends Vue {
  post: any = {};
  popular: any[] = [];

  error: string = "";

  async mounted() {
    await this.loadData();
    await this.loadPopular();
  }

  async loadData() {
    let url = `${Settings.HostName}/api/cztextblocks/get-with-user?id=${this.$route.params.id}`;
    let data = await new CrudService().httpGet(url);

    if (data == null) {
      console.error("Crash getting post");
      return;
    }
    this.post = data;
  }

  async loadPopular() {
    const url = `${Settings.HostName}/api/cztextblocks/list-with-users?count=2&popular=true`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting last posts");
      return;
    }
    this.popular = data;
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return store.state.settings.Uploads + "/" + name;
    }
    return store.state.settings.Uploads + "/" + store.state.settings.Mainlogo;
  }

  getDateFormatted(dt: string) {
    return DateHelper.formatDate(new Date(dt));
  }
}
