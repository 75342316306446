
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import { Global } from "../../core/helpers/global";

/***
 * Emits click
 */

@Component({
  components: { },
})
export default class TriggerAnchor extends Vue {
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop({ default: "#" }) href!: string;


  mounted() {
  }

  @Emit("click")
  internalOnClick(e: any) {
    Global.TriggerDomId = this.id;
    return e;
  }
}
