
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class NetPay3dsPayment extends Vue {
  data: any = null;
  errorMsg: string = "";

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    let url = `${Settings.HostName}/api/netpay/callback/${this.$route.query.id}/${this.$route.query.transaction_token}`;
    let data = await new CrudService().httpPost(url);
    if (data == null) {
      this.$router.push("/cz/ko");
      return;
    }

    if (data.ok === true) {
      this.$router.push("/cz/ok");
      return;
    }
    this.$router.push("/cz/ko");
  }
}
