
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit, Watch } from "vue-property-decorator";
import Accordion from "../components/accordion/accordion.vue";

@Component({
  components: { Accordion },
})
export default class BookingsFilters extends Vue {
  //@Prop() resourceTypes!: any[];
  @Prop() bookingTypes!: any[];
  @Prop() close;
  @Prop() open;

  bookingTypesByCategory: any[] = [];
  bookingTypesNoCategory: any[] = [];

  mounted() {
    this.parseBookingTypes();
    // this.setUpResourceTypeFilter();
  }

  parseBookingTypes() {
    if (this.bookingTypes === null || this.bookingTypes === undefined) {
      return null;
    }

    for (const bt of this.bookingTypes) {
      if (bt.category === "" || bt.category === null) {
        this.bookingTypesNoCategory.push({ ...bt });
        continue;
      }

      var found = this.bookingTypesByCategory.find(
        (y) => y.category === bt.category
      );
      if (found !== undefined) {
        found.items.push({ ...bt });
        continue;
      }

      this.bookingTypesByCategory.push({
        category: bt.category,
        items: [{ ...bt }],
      });
    }
  }

  @Watch("close")
  closeFilters() {
    let dom: any = document.getElementById("filter-menu");
    dom.classList.remove("filter-open");
    dom.classList.add("filter-closed");
  }

  @Watch("open")
  openFilters() {
    let dom: any = document.getElementById("filter-menu");
    dom.classList.remove("filter-closed");
    dom.classList.add("filter-open");

    let domFilterClose = document.getElementById("dom-filter-close");
    domFilterClose?.classList.add("active");
  }

  setUpBookingTypeFilter() {
    if (this.bookingTypes.length === 1) {
      const domBookingTypes: any = this.$refs.accordion1;
      domBookingTypes.style.display = "none";
    }
  }

  // setUpResourceTypeFilter() {
  //   if (this.resourceTypes === undefined || this.resourceTypes.length <= 1) {
  //     const dom: any = this.$refs.accordion3;
  //     dom.style.display = "none";
  //   }
  // }

  @Emit("bookingTypeSelected")
  selectBookingType(bt: any) {
    for (const bt of this.bookingTypes) {
      const dom: any = document.getElementById(`checkbox-bookingtype-${bt.id}`);
      dom.classList.remove("selected");
    }
    const dom: any = document.getElementById(`checkbox-bookingtype-${bt.id}`);

    dom.classList.add("selected");
    return bt;
  }

  // @Emit("resourceTypeSelected")
  // resourceTypeSelected(id: any) {
  //   for (const bt of this.resourceTypes) {
  //     const dom: any = document.getElementById(
  //       `checkbox-resourcetype-${bt.id}`
  //     );
  //     dom.classList.remove("selected");
  //   }

  //   if (id !== null) {
  //     const dom: any = document.getElementById(`checkbox-resourcetype-${id}`);
  //     dom.classList.add("selected");
  //   }
  //   return id;
  // }
}
