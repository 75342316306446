
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import InputPassword from "../components/form/inputPassword.vue";
import SubmitButton from "../components/submitButton/submitButton.vue";

@Component({
  components: {
    InputPassword,
    SubmitButton,
  },
})
export default class ChangePasswordPage extends Vue {
  responseMsg: string = "";
  newPass: string = "";
  repeatPass: string = "";
  errorNew: string = "";
  errorRepeat: string = "";
  passToken: string = "";

  created() {
    this.passToken = this.$route.params.token;
  }

  async save(e: any) {
    e.preventDefault();

    this.responseMsg = "";
    this.errorRepeat = "";
    this.errorNew = "";

    if (
      this.newPass === "" ||
      this.newPass === undefined ||
      this.newPass === null
    ) {
      this.errorNew = this.$t("Required").toString();
    }
    if (
      this.repeatPass === "" ||
      this.repeatPass === undefined ||
      this.repeatPass === null
    ) {
      this.errorRepeat = this.$t("Required").toString();
    }

    if (this.newPass.length < 5) {
      this.errorNew += " " + this.$t("Minimun length is 5").toString();
    }

    if (this.repeatPass.length < 5) {
      this.errorRepeat += " " + this.$t("Minimun length is 5").toString();
    }

    if (this.newPass !== this.repeatPass) {
      this.errorRepeat += " " + this.$t("Password does not match");
      this.errorNew += " " + this.$t("Password does not match");
    }

    if (this.errorRepeat !== "" || this.errorNew !== "") {
      return;
    }

    let url = `${Settings.HostName}/api/publiccustomers/reset-password-cz`;

    const body = {
      token: this.passToken,
      plainPassword: this.newPass,
    };

    let data = await new CrudService().httpPost(url, body);

    if (data == null || data === undefined || data.ok === false) {
      this.responseMsg = "";
      this.errorRepeat = "";
      this.responseMsg = this.$t("Crash").toString();
      return;
    }
    this.errorNew = "";
    this.errorRepeat = "";
    this.responseMsg = this.$t("Data modified!").toString();
    this.$router.push("/passReset/ok");
  }
}
