import { Calcs } from "./Calcs";

export class DrawTools {
  public bkContent: any;

  public href = "";
  public svgContent: any;
  public image: any = {};
  public onLoadBk: any;
  public onResize = false;
  public onMove = false;
  public timeOnResize: number = 10;
  public dragOn = false;
  public resizeOn = false;
  public multiply = 1;
  public scale = 1;
  public mousedn1 = false;
  public clickToMap: any;
  public moveSelect: any;
  public obj: any = {};
  public cursor = "move";

  private myfuncHand: any = {};
  private sizeOld = { w: window.innerWidth, h: window.innerHeight };

  public init() {
    this.sizeOld.w = window.innerWidth;
    this.sizeOld.h = window.innerHeight;
    this.loadBk();
    if (this.onResize) {
      this.onResizeFnc();
    }

    if (this.onMove) {
      if (this.svgContent !== undefined) {
        this.svgContent.onmousedown = (event: any) => {
          if (!this.dragOn && !this.resizeOn) {
            this.mousedn1 = true;
            this.downFnc(event);
          }
        };
      }
    }
  }
  public loadBk() {
    const img1 = this.getImgAttr(this.href);
    img1.onload = (e: any) => {
      this.bkContent = e.target;
      const mySvgCnt: any = this.svgContent;

      if (mySvgCnt !== undefined) {
        this.putBk();
      }
      this.onLoadBk(this.image);
    };
  }

  public resized(param?: any) {
    if (
      this.sizeOld.w !== window.innerWidth ||
      this.sizeOld.h !== window.innerHeight
    ) {
      if (!this.mousedn1) {
        param.image.href = "";
        param.image.w = 0;
        param.image.h = 0;

        setTimeout(() => {
          param.loadBk();
          this.sizeOld.w = window.innerWidth;
          this.sizeOld.h = window.innerHeight;
        }, this.timeOnResize);
      }
    }
  }

  public onResizeFnc() {
    window.removeEventListener("resize", this.obj.resize);
    window.addEventListener(
      "resize",
      (this.obj.resize = () => {
        this.resized(this);
      })
    );
  }
  public putBk() {
    const img = this.bkContent;
    const w0 = img.width;
    const h0 = img.height;
    //const r = h0 / w0;

    const w1 = w0;
    const h1 = h0;

    this.image = {
      href: this.href,
      w: w1 * this.multiply,
      h: h1 * this.multiply
    };
  }

  public calcMove(e1: any, e2: any) {
    const dx = e1.offsetX - e2.offsetX;
    const dy = e1.offsetY - e2.offsetY;

    const elmnt = this.svgContent;
    const x1 = elmnt.scrollLeft;
    const y1 = elmnt.scrollTop;

    this.svgContent.scrollTo(dx + x1, dy + y1);
  }

  public calcSelect(e1: any, e2: any) {
    const dx = e1.offsetX - e2.offsetX;
    const dy = e1.offsetY - e2.offsetY;

    const elmnt = this.svgContent;
    const x1 = elmnt.scrollLeft;
    const y1 = elmnt.scrollTop;
    const params: any = {
      dx,
      dy,
      x1,
      y1,
      e1,
      e2
    };
    //if (typeof (this.moveSelect) === "Function")) {
    this.moveSelect(params);
    //}
  }

  public downFnc(event: any) {
    this.mousedn1 = true;

    //  if (typeof (this.clickToMap === "Function")) {
    this.clickToMap(event);
    //  }

    window.addEventListener(
      "mouseup",
      (this.myfuncHand.mouseUp = () => {
        event.target.onmousemove = undefined;
        window.removeEventListener("mouseup", this.myfuncHand.mouseUp);
        setTimeout(() => {
          this.mousedn1 = false;
          if (this.cursor === "select") {
            //if (typeof (this.moveSelect === "Function")) {
            this.moveSelect({});
            //}
          }
        }, 100);
      }),
      false
    );

    event.target.onmousemove = (eventmove: any) => {
      if (this.cursor === "move") {
        this.calcMove(event, eventmove);
      }

      if (this.cursor === "select") {
        this.calcSelect(event, eventmove);
      }
    };
  }

  private getImgAttr(address: string) {
    const myPicXO = new Image();
    myPicXO.src = address;
    return myPicXO;
  }
}
