import { render, staticRenderFns } from "./myOrderPage.vue?vue&type=template&id=05fb0e27&scoped=true&"
import script from "./myOrderPage.vue?vue&type=script&lang=ts&"
export * from "./myOrderPage.vue?vue&type=script&lang=ts&"
import style0 from "./myOrderPage.vue?vue&type=style&index=0&id=05fb0e27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../padel/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05fb0e27",
  null
  
)

export default component.exports