
var isNoviBuilder = window.xMode;
function InitCount() {
    var $document = $(document);

    for (var i = 0; i < $(".counter").length; i++) {
        let $counterNotAnimated = $($(".counter")[i]).not('.animated');
        $document.on("scroll", $.proxy(function () {
            let $this = this;

            if ((!$this.hasClass("animated")) && (isScrolledIntoView($this))) {
                $this.countTo({
                    refreshInterval: 40,
                    from: 0,
                    to: parseInt($this.text(), 10),
                    speed: $this.attr("data-speed") || 1000
                });
                $this.addClass('animated');
            }
        }, $counterNotAnimated))
            .trigger("scroll");
    }
}

function isScrolledIntoView(elem) {
    var $window = $(window);
    if (isNoviBuilder) return true;
    return elem.offset().top + elem.outerHeight() >= $window.scrollTop() && elem.offset().top <= $window.scrollTop() + $window.height();
}

export { InitCount };