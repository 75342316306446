
import { Loader } from "@shared/core/helpers/loaderHelper";
import { TicketService } from "@shared/core/services/ticketService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class PublicTicket extends Vue {
  data: any = null;
  isError: boolean = false;
  finished: boolean = false;
  ticketService: TicketService = new TicketService();

  async mounted() {
    await this.loadData();
  }

  async loadData() {
    Loader.Loading("inProgress");
    await this.ticketService.downloadTicketPdf(this.$route.params.token, this.handlePDFResponse);
  }

  private handlePDFResponse(ok: boolean, dataOrErrors: any) {
    this.finished = true;
    Loader.Unloading("inProgress");

    if (ok) return;

    this.handleErrors(dataOrErrors);
  }

  private handleErrors(dataOrErrors: any) {
    this.isError = true;
  }
}
