
import { Component, Prop, Vue } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";
import { CrudService } from "@shared/core/services/crudService";
import { Settings } from "@shared/core/services/configService";
import store from "../../core/store";

@Component({
  components: {},
})
export default class BookingSummary extends Vue {
  @Prop() booking: any;

  resourceImg: string = "";
  async mounted() {
    const url = `${Settings.HostName}/api/czconfigs/template`;
    const data = await new CrudService().httpGet(url);
    if (data == null || data.data == null || !data.ok) {
      console.error("Crash getting cz template settings");
      return;
    }
    this.resourceImg = store.state.settings.Uploads + "/" + data.data.image1;
  }

  getBeautifyDate() {
    return DateHelper.beautifyDate(this.booking.date, true, false, true);
  }
}
