import esES from "./es-ES.json";
import esMX from "./es-MX.json";
import enUS from "./en-US.json";
import frFR from "./fr-FR.json";
import itIT from "./it-IT.json";
import { Locales } from "@shared/core/constants";


export const LOCALES = [
    { value: Locales.enUS, caption: 'English USA' },
    { value: Locales.esES, caption: 'Español' },
    { value: Locales.frFR, caption: 'Francais' },
    { value: Locales.itIT, caption: 'Italiano' }
];

export const messages = {
    [Locales.esES]: esES,
    [Locales.esMX]: esMX,
    [Locales.enUS]: enUS,
    [Locales.frFR]: frFR,
    [Locales.itIT]: itIT
};

export const defaultLocale = Locales.enUS;

