
import { Settings, TenantSettings } from "@shared/core/services/configService";
import {
  Component,
  Vue,
  Watch,
} from "@shared/node_modules/vue-property-decorator";

import VueGallery from "../../../shared/node_modules/vue-gallery";
import { CrudService } from "../../../shared/core/services/crudService";
import LoginRegisterDialog from "../../../shared/components/login/loginRegisterDialog.vue";
import { LocalStorage } from "../../../shared/core/localStorage";
import store from "../../../shared/core/store";
import MenuShopCart from "../../../shared/components/menuShopCart/menuShopCart.vue";
import MenuSearch from "../../../shared/components/menuSearch/menuSearch.vue";
import MenuUpper from "../../../shared/components/menuUpper/menuUpper.vue";
import { RdNavBar } from "../js/rdnavbar.js";
import "../js/script.js";
import { ShopService } from "../../../shared/core/services/shopService";

@Component({
  components: {
    VueGallery,
    LoginRegisterDialog,
    MenuShopCart,
    MenuSearch,
    MenuUpper,
  },
})
export default class CzMenu extends Vue {
  settings: TenantSettings = {};
  logoUrl: string | null = null;
  images: any[] = [];
  index: any = null;
  posts: any[] = [];
  hasShop: boolean | undefined = false;

  async mounted() {
    this.settings = store.state.settings;
    this.hasShop = this.settings.HasShop;
    if (this.settings.Mainlogo !== null) {
      this.logoUrl = this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    await this.loadPosts();
    await this.loadGallery();
  }

  isIos() {
    return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
      navigator.userAgent
    );
  }

  showMyAccount() {
    const isResponsive: any =
      document.getElementsByClassName("rd-navbar-fixed");

    if (isResponsive.length === 0) {
      return this.$router.push("/account");
    }

    const myAcount: any = document.getElementsByClassName(
      "rd-navbar--has-megamenu"
    )[0];

    if (myAcount.classList.contains("opened")) {
      myAcount.classList.remove("opened");
      return;
    }
    myAcount.classList.add("opened");
  }

  async loadPosts() {
    const url = `${Settings.HostName}/api/cztextblocks/list-with-users?count=2`;

    const data = await new CrudService().httpGet(url);
    if (data == null) {
      console.error("Crash getting last posts");
      return;
    }
    this.posts = data;
  }

  getImage(name: string) {
    if (name !== undefined && name !== "" && name !== null) {
      return this.settings.Uploads + "/" + name;
    }
    return this.settings.Uploads + "/" + this.settings.Mainlogo;
  }

  logInOut() {
    if (store.state.loggedIn !== true) {
      store.commit("openLogin", { backUrl: window.location.pathname });
      return;
    }
    new ShopService().removeCart();
    LocalStorage.delete("ctk");
    LocalStorage.delete("cc");
    window.location.reload();
  }

  async loadGallery() {
    const dbGallery = localStorage.getItem(Settings.Prefix + "all-gallery");
    if (Vue.prototype.$last !== "/" && dbGallery !== null) {
      this.images = JSON.parse(dbGallery).map((y) => y.url);
      return;
    }

    const url = `${Settings.HostName}/api/fileuploads/open-list?parentName=CZConfig&parentId=1&category=CzGallery`;
    const data = await new CrudService().httpGet(url);
    if (data == null || !data.ok) {
      console.error("Crash getting cz gallery");
      return;
    }

    for (const image of data.data) {
      image.url = this.settings.Uploads + "/CzConfig/CzGallery/" + image.url;
      this.images.push(image.url);
    }

    localStorage.setItem(
      Settings.Prefix + "all-gallery",
      JSON.stringify(data.data)
    );
  }

  @Watch("$route.fullPath")
  toggleMenu() {
    const button: any = this.$refs.toggleButton;
    const menu: any = this.$refs.toggleMenu;
    if (!button.classList.contains("active")) {
      return;
    }

    button.classList.remove("active");
    menu.classList.remove("active");
  }
}
