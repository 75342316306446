
import Vue from "vue";
import Component from "@modules/vue-class-component";
import { CrudService } from "../../core/services/crudService";
import Popup from "@shared/components/popup/popup.vue";
import { Emit, Prop } from "vue-property-decorator";
import { Settings } from "@shared/core/services/configService";
import { Loader } from "@shared/core/helpers/loaderHelper";

@Component({
  components: { Popup },
})
export default class TransbankPM extends Vue {
  @Prop({required: true}) customerId!: number;
  error: string = "";

  async mounted() {
    Loader.Loading("transbank-loader");
    this.addTransbankToken();
  }

  async addTransbankToken() {
    var url = `${Settings.HostName}/api/transbank/tokenize/${this.customerId}?fromPublic=true`;
    var data = await new CrudService().httpPost(url);
    if (data == null) {
      this.error = this.$t("Crash").toString();
      return;
    }

    if (!data.ok) {
      this.error = data.errorMsg;
      return;
    }

    if (data.model !== null) {
      window.location.href = data.model.redirectUrl;
      return;
    }
  }

  @Emit("close")
  closed() { }
}
