
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Popup from "../popup/popup.vue";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import InputPassword from "../../components/form/inputPassword.vue";
import Checkbox from "../../components/form/checkBox.vue";
import InputText from "../../components/form/inputText.vue";
import SubmitButton from "../../components/submitButton/submitButton.vue";
import DynamicForm from "../../components/dynamicForm/dynamicForm.vue";
import LegalCheck from "../legalCheck/legalCheck.vue";
import PubliCheck from "../publiCheck/publiCheck.vue";
import { CustomRegisterForm } from "../../crm/constants";

@Component({
  components: {
    Popup,
    InputPassword,
    Checkbox,
    InputText,
    SubmitButton,
    DynamicForm,
    LegalCheck,
    PubliCheck,
  },
})
export default class RegisterForm extends Vue {
  @Prop({ default: false }) open!: boolean;
  @Prop({ default: false }) registerOption!: boolean;
  @Prop() returnUrl!: string;
  email: string = "";
  password: string = "";
  resetPassEmail: string = "";
  name: string = "";
  surname: string = "";
  registerEmail: string = "";
  registerPass: string = "";
  phone: string = "";
  okRss: boolean = true;
  acceptConditions: boolean = false;

  error: string = "";
  registerOk: boolean = false;
  showDynamicForm: boolean = true;

  mounted() {
    this.error = "";
  }

  submit(e: any) {
    e.preventDefault();

    this.error = "";
    this.registerOk = false;

    this.register();
  }

  @Emit("onRegister")
  async register() {
    if (!this.acceptConditions) {
      this.error = this.$t("Please accept our legal conditions").toString();
      return;
    }

    let url = `${Settings.HostName}/api/publiccustomers/register`;
    let data = await new CrudService().httpPost(url, {
      name: this.name,
      surname: this.surname,
      email: this.registerEmail,
      password: this.registerPass,
      phone: this.phone,
      okRss: this.okRss,
      acceptConditions: this.acceptConditions,
    });

    if (data == null) {
      console.error("Crash on login");
      return;
    }

    if (data.ok === false) {
      this.error = data.errorMsg;
      return;
    }

    this.registerOk = true;
    return { ok: true, id: data.model.id };
  }

  buttonIsVisible() {
    return !this.registerOk;
  }

  dynamicFormLoaded(hasData: boolean) {
    this.showDynamicForm = hasData;
  }

  dynamicFormSaved(data: any) {
    this.registerOk = data.success;
    return this.onRegistered(data);
  }

  @Emit("onRegister")
  onRegistered(data) {
    if (data.success) {
      return { ok: true, id: data.model };
    }
  }

  getParent() {
    return CustomRegisterForm;
  }
}
