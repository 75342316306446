
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import Checkbox from "../form/checkBox.vue";
import { Settings } from "../../core/services/configService";
import { CrudService } from "../../core/services/crudService";
import Popup from "../popup/popup.vue";

@Component({
  components: { Checkbox, Popup },
})
export default class LegalLinks extends Vue {
  loaded: boolean = false;
  textOkButton: string = "";

  waiver: string = "";
  privacyPolicyFull: string = "";
  cookiesPolicy: string = "";
  generalConditions: string = "";

  showPopUpWaiver: boolean = false;
  showPopUpGeneral: boolean = false;
  showPopUpPrivacy: boolean = false;
  showPopUpCookies: boolean = false;

  mounted() {
    this.textOkButton = this.$t("Ok").toString();
  }

  @Emit("input")
  onChange(val: any) {
    return val;
  }

  async load(file: string) {
    if (!this.loaded) {
      let url = `${Settings.HostName}/api/czconfigs/legal-settings`;
      let data = await new CrudService().httpGet(url);
      if (data == null || data.data == null || !data.ok) {
        console.error("Crash getting cz legal settings");
        return;
      }

      this.waiver = data.data.waiver;
      this.privacyPolicyFull = data.data.privacyPolicyFull;
      this.cookiesPolicy = data.data.cookiesPolicy;
      this.generalConditions = data.data.generalConditions;
    }

    switch (file) {
      case "general":
        this.showPopUpGeneral = true;
        break;
      case "privacy":
        this.showPopUpPrivacy = true;
        break;
      case "cookies":
        this.showPopUpCookies = true;
        break;
      case "waiver":
        this.showPopUpWaiver = true;
        break;
    }

    this.loaded = true;
  }
}
