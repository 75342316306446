
import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { LocalStorage } from "../../../shared/core/localStorage";
import Gallery from "../../../shared/components/gallery/gallery.vue";
import store from "../../../shared/core/store";

@Component({
  components: {
    Gallery,
  },
})
export default class TrainingPage extends Vue {
  data: any = null;
  galleryPath: string = "";
  filesPath: string = "";

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    let url = `${Settings.HostName}/api/publicteams/training`;
    let data = await new CrudService().httpPost(url, {
      customerId: cid,
      itemId: this.$route.params.id,
    });

    if (data == null || data === undefined || data.ok === false) {
      console.error("Crash getting training " + this.$route.params.id);
      return;
    }
    this.data = data.model;
    this.galleryPath = store.state.settings.Uploads + "/Booking/Gallery/";
    this.filesPath = store.state.settings.Uploads + "/Booking/Files/";
  }
}
