var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{staticClass:"breadcrumbs-custom-inset"},[_c('div',{staticClass:"breadcrumbs-custom context-dark"},[_c('div',{staticClass:"container"}),_c('div',{staticClass:"box-position",style:(`background-image: url('${_vm.$store.state.settings.HeaderImage}')`)})])]),_c('section',{staticClass:"section section-sm section-last bg-default text-md-left"},[_c('div',{staticClass:"container"},[_c('h5',[_vm._v(_vm._s(_vm.$t("Communications")))]),(_vm.errorMsg != null && _vm.errorMsg != '')?_c('div',{staticClass:"background-main-color"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('div',{staticClass:"row row-15 align-items-start card-group-custom card-group-corporate",attrs:{"id":"accordion1","role":"tablist","aria-multiselectable":"false"}},[_c('div',{staticClass:"col-lg-12"},[_c('article',{staticClass:"card card-custom card-corporate"},[_c('div',{staticClass:"card-header hideScroll",attrs:{"id":"accordion1Heading1","role":"tab"}},[_c('div',{staticClass:"card-title"},[_c('a',{staticClass:"collapsed main-color fl-bigmug-line-email64",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion1","href":"#accordion1Collapse1","aria-controls":"accordion1Collapse1"}},[_vm._v(_vm._s(_vm.$t("Emails"))+" "),_c('div',{staticClass:"card-arrow"})])])]),_c('div',{staticClass:"collapse",attrs:{"id":`accordion1Collapse1`,"role":"tabpanel","aria-labelledby":`accordion1Heading1`}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-50 justify-content-center"},[_c('div',{staticClass:"w-100"},[(
                        _vm.data === null ||
                        _vm.data === undefined ||
                        _vm.data.emails.length === 0
                      )?_c('article',{staticClass:"card card-custom card-corporate marginTop"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-0 align-items-start card-group-custom card-group-corporate"},[_c('article',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.$t("No emails"))+" ")])])])]):_vm._e(),(
                        _vm.data !== null &&
                        _vm.data !== undefined &&
                        _vm.data.emails.length > 0
                      )?_c('div',{staticClass:"table-custom-responsive"},[_c('table',{staticClass:"table-custom"},[_c('tbody',_vm._l((_vm.data.emails),function(sItem,index){return _c('tr',{key:index},[_c('td',{staticClass:"tblCol td-info cursor-pointer w-60",on:{"click":function($event){return _vm.showEmail(sItem.id)}}},[_vm._v(" "+_vm._s(sItem.subject)+" ")]),_c('td',{staticClass:"tblCol td-date cursor-pointer",on:{"click":function($event){return _vm.showEmail(sItem.id)}}},[_vm._v(" "+_vm._s(sItem.created)+" ")])])}),0)])]):_vm._e()])])])])])])]),_c('div',{staticClass:"row rowMarginTop row-15 align-items-start card-group-custom card-group-corporate",attrs:{"id":"accordion2","role":"tablist","aria-multiselectable":"false"}},[_c('div',{staticClass:"col-lg-12"},[_c('article',{staticClass:"card card-custom card-corporate"},[_c('div',{staticClass:"card-header hideScroll",attrs:{"id":"accordion2Heading1","role":"tab"}},[_c('div',{staticClass:"card-title"},[_c('a',{staticClass:"collapsed main-color fl-bigmug-line-phone351",attrs:{"role":"button","data-toggle":"collapse","data-parent":"#accordion2","href":"#accordion2Collapse1","aria-controls":"accordion2Collapse1"}},[_vm._v(_vm._s(_vm.$t("Sms"))+" "),_c('div',{staticClass:"card-arrow"})])])]),_c('div',{staticClass:"collapse",attrs:{"id":`accordion2Collapse1`,"role":"tabpanel","aria-labelledby":`accordion2Heading1`}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-50 justify-content-center"},[_c('div',{staticClass:"w-100"},[(
                        _vm.data === null ||
                        _vm.data === undefined ||
                        _vm.data.smss.length === 0
                      )?_c('article',{staticClass:"card card-custom card-corporate marginTop"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-0 align-items-start card-group-custom card-group-corporate"},[_c('article',{staticClass:"w-100"},[_vm._v(" "+_vm._s(_vm.$t("No sms"))+" ")])])])]):_vm._e(),(
                        _vm.data !== null &&
                        _vm.data !== undefined &&
                        _vm.data.smss.length > 0
                      )?_c('div',{staticClass:"table-custom-responsive"},[_c('table',{staticClass:"table-custom"},[_c('tbody',_vm._l((_vm.data.smss),function(sItem,index){return _c('tr',{key:index},[_c('td',{staticClass:"tblCol td-info w-60"},[_vm._v(" "+_vm._s(sItem.body)+" ")]),_c('td',{staticClass:"tblCol td-date"},[_vm._v(" "+_vm._s(sItem.created)+" ")])])}),0)])]):_vm._e()])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }