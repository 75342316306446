
import { Component, Vue, Prop } from "vue-property-decorator";
import VueGallery from "vue-gallery";

@Component({
  components: {
    VueGallery,
  },
})
export default class Gallery extends Vue {
  @Prop() galleryData!: any[];
  @Prop() title!: string;
  @Prop() galleryPath!: string;

  index: any = null;
  images: any[] = [];

  mounted() {
    for (const data of this.galleryData) {
      let imagePath = data.url;
      if (this.galleryPath !== undefined) {
        imagePath = this.galleryPath + "/" + data.url;
      }
      this.images.push(imagePath);
    }
  }

  getAnimation(imgNumber: number) {
    switch (imgNumber) {
      case 0:
        return "slideInLeft";
      case 1:
        return "slideInUp";
      case 2:
        return "slideInRight";
      case 3:
        return "slideInUp";
      case 4:
        return "slideInLeft";
      case 5:
        return "slideInDown";
    }
  }
}
