
import { CrudService } from "../../core/services/crudService";
import { Settings, TenantSettings } from "../../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { Format } from "../../core/helpers/formatHelper";
import store from "../../core/store";
import InputText from "../../components/form/inputText.vue";
import { Emit } from "vue-property-decorator";

@Component({
  components: { InputText },
})
export default class LookUp extends Vue {
  settings: TenantSettings = {};
  searchResults: any = null;
  search: string = "";
  gridStatus: string = "";
  error: string = "";

  async mounted() {
    this.settings = store.state.settings;
  }

  async loadData() {
    let url = `${Settings.HostName}/api/publiccustomers/list`;
    let data = await new CrudService().httpPost(url, this.search);

    if (data == null || data === undefined || data.ok === false) {
      this.error = this.$t("crash").toString();
      return;
    }

    this.searchResults = data.results;
    window.scrollTo(0, 0);
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }

  formatPrice(price: string) {
    return Format.formatCurrency(price);
  }

  async selectSearch() {
    if (this.search === "") {
      this.searchResults = null;
      return;
    }
    if (this.search.length >= 3) {
      await this.loadData();
    }
  }

  clearSearch() {
    this.search = "";
    this.searchResults = null;
  }

  closeForm() {
    const dom: any = this.$refs.toggleButton;
    const domInput: any = this.$refs.toggleDiv;
    if (dom.classList.contains("active")) {
      dom.classList.remove("active");
      domInput.classList.remove("active");
    }

    this.clearSearch();
  }

  getLabel(item: any) {
    return Format.highLightText(item, this.search);
  }

  @Emit("selected")
  selectResult(e: any, result: any) {
    return result;
  }

  goToShop(e: any, id: any) {
    e.preventDefault();
    let shopPageSearch = this.search;
    this.closeForm();

    if (id === null || id === undefined || shopPageSearch === "") {
      this.$router.push("/shop");

      this.$router.push({
        name: "shop",
        query: {
          search: shopPageSearch,
        },
      });

      return;
    }

    this.$router.push(`/shop/${id}`);
  }
}
