
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Settings } from "../../core/services/configService";
import CheckBox from "../form/checkBox.vue";
import TextArea from "../form/textArea.vue";
import InputDate from "../form/inputDate.vue";
import InputPassword from "../form/inputPassword.vue";
import InputSelect from "../form/inputSelect.vue";
import InputText from "../form/inputText.vue";
import InputFile from "../form/inputFile.vue";
import Popover from "../popover/popover.vue";
import { InputType } from "./inputTypes";
import { CrudService } from "../../core/services/crudService";
import { Format } from "../../core/helpers/formatHelper";

@Component({
  components: {
    CheckBox,
    InputDate,
    InputPassword,
    InputSelect,
    InputText,
    Popover,
    TextArea,
    InputFile,
  },
})
export default class DynamicField extends Vue {
  @Prop() fdata!: any;
  @Prop() label!: string;
  @Prop() errors!: string[];

  inputTypes = InputType;
  lookupOptions: any | null = null;

  async created() {
    if (this.fdata.editInputType === this.inputTypes.Picklist) {
      this.fdata.value = -1;
    }
    if (this.fdata.editInputType === this.inputTypes.Lookup) {
      this.fdata.value = -1;
      await this.getLookupOptions();
    }
  }

  async getLookupOptions() {
    // convert lookup to picklist
    if (this.fdata.floatingFilterComponentParams.processed__) {
      return this.getPickListOptions();
    }
    const url = `${Settings.HostName}/api/public${this.fdata.floatingFilterComponentParams.fkController}/list?startRow=0&endRow=50`;
    let res = await new CrudService().httpGet(url);
    if (res == null || res.ok === false) {
      console.error("Crash");
      return;
    }

    let fkLabel = Format.toLowerFirst(
      this.fdata.floatingFilterComponentParams.fkLabel
    );
    let fkId = Format.toLowerFirst(
      this.fdata.floatingFilterComponentParams.fkId
    );

    this.fdata.floatingFilterComponentParams = { processed__: true };
    for (const line of res.rowData) {
      this.fdata.floatingFilterComponentParams[line[fkLabel]] = line[fkId];
    }

    this.lookupOptions = this.getPickListOptions();
  }

  getPickListOptions() {
    if (this.fdata === null) {
      return;
    }

    var metaOpts = this.fdata.floatingFilterComponentParams;

    var items: any[] = [];
    Object.keys(metaOpts).forEach(function (item) {
      if (
        item != "typeFilterButton" &&
        item != "suppressFilterButton" &&
        item !== "processed__"
      ) {
        items.push({
          value: metaOpts[item],
          label: item,
        });
      }
    });

    items.push({
      value: -1,
      label: this.fdata.headerName,
      selected: true,
    });
    return items;
  }

  @Emit("input")
  emitValue(value: any) {
    return value;
  }
}
