import { Calcs } from "./Calcs";

export class EditPathMath {
  public scale: number = 1;
  public nrange: number = 1;
  public multiply: number = 1;
  private calcs: Calcs = new Calcs();

  public setEditDraw(dat: any) {
    const vedit: any = [];
    let transform = "";

    const sizeIconResize = 12 * this.multiply * (1 / Number(this.nrange));

    const cx: number = +Math.ceil(dat.x);
    const cy: number = +Math.ceil(dat.y);
    const w = sizeIconResize;
    const h = sizeIconResize;
    if (dat.type === "rect") {
      const w0: number = Math.ceil(dat.width);
      const h0: number = Math.ceil(dat.height);
      const c = [
        { x: cx - w / 2, y: cy - h / 2 },
        { x: cx + w0 - w / 2, y: cy - h / 2 },
        { x: cx + w0 - w / 2, y: cy + h0 - h / 2 },
        { x: cx - w / 2, y: cy + h0 - h / 2 }
      ];

      for (let i = 0; i < 4; i++) {
        const t1 = " translate(" + c[i].x + "," + c[i].y + ") ";
        vedit.push({ x: c[i].x, y: c[i].y, w, h, dat, i, transform: t1 });
      }
    }
    if (dat.type === "path") {
      const defv = dat.def.split(" ");
      const n2 = defv.length;
      let x1 = 0;
      let y1 = 0;
      let def: any = [];
      for (let i = 0; i < n2; i++) {
        let p = false;
        let aa: any = [];
        if (defv[i] === "M" || defv[i] === "l") {
          p = true;
          aa[0] = defv[i + 1];
          aa[1] = defv[i + 2];
          def = aa;
        }

        if (defv[i] === "a") {
          aa = defv[i + 6].split(",");
          def = aa.concat([
            defv[i + 1],
            defv[i + 2],
            defv[i + 3],
            defv[i + 4],
            defv[i + 5],
            defv[i + 6]
          ]);
          p = true;
        }

        if (p) {
          x1 = x1 + Math.ceil(aa[0]);
          y1 = y1 + Math.ceil(aa[1]);
          const c = [{ x: cx + x1 - w / 2, y: cy + y1 - h / 2 }];
          transform = " translate(" + c[0].x + "," + c[0].y + ")  ";
          vedit.push({
            x: c[0].x,
            y: c[0].y,
            x1,
            y1,
            w,
            h,
            dat,
            i,
            transform,
            css: "",
            type: defv[i],
            def
          });
        }
      }
    }

    const n = vedit.length;

    if (vedit[0].transform === vedit[n - 1].transform) {
      vedit[n - 1].css = "hidden";
    }
    for (let i = 0; i < n; i++) {
      const ve1 = vedit[i];
      let i2 = i + 1;
      if (i === n - 1) {
        i2 = 0;
      }
      const ve2 = vedit[i2];

      const ve = Object.assign({}, ve1);
      const dx = (ve2.x - ve1.x) / 2;
      const dy = (ve2.y - ve1.y) / 2;
      let x1 = ve1.x + dx;
      let y1 = ve1.y + dy;

      let h1 = 0;
      let hx = 0;
      let hy = 0;
      let sgn = 1;

      if (ve2.type === "a") {
        const d = Math.sqrt(dx * dx + dy * dy);
        let r = ve2.def[2];
        const an1 = Math.abs(Math.atan(dx / dy));

        let an2 = 1.5707963267949;
        if (r > d) {
          an2 = Math.abs(Math.asin(d / r));
        } else {
          r = d;
          ve2.def[2] = r;
        }

        if (ve2.def[6] === "0") {
          sgn = -1;
        }
        const sgnX = this.calcs.sign(dx);
        const sgnY = this.calcs.sign(dy);

        h1 = r * (1 - Math.cos(an2));
        hx = -sgn * sgnY * h1 * Math.cos(an1);
        hy = sgn * sgnX * h1 * Math.sin(an1);

        x1 = x1 - hx;
        y1 = y1 - hy;
      }
      ve.i = ve.i + 0.5;
      ve.x = x1;
      ve.y = y1;
      ve.hx = hx;
      ve.hy = hy;
      ve.h1 = h1;
      ve.sgn = sgn;
      ve.w = sizeIconResize;
      ve.h = sizeIconResize;
      ve.prev = ve1;
      ve.next = ve2;
      ve.css = "cent";
      ve.type = ve2.type;

      ve.transform = " translate(" + x1 + "," + y1 + ")";

      if (ve2.i !== 0) {
        vedit.push(ve);
      }
    }
    let rot = 0;
    if (!isNaN(dat.rot)) {
      rot = dat.rot;
    }
    const transformGroup =
      " translate(" +
      Math.ceil(dat.x0) +
      "," +
      Math.ceil(dat.y0) +
      ") rotate(" +
      rot +
      ") ";

    return {
      vedit,
      transform: transformGroup
    };
  }

  public resizeDraw(dat: any, dx1: number, dy1: number, op: number) {
    if (op === 1) {
      return this.resizeDrawSize(dat, dx1, dy1);
    }
    if (op === 2) {
      return this.resizeDrawCurve(dat, dx1, dy1);
    }
  }

  public resizeDrawCurve(dat: any, dx1: number, dy1: number) {
    const def = dat.dat.def;
    let defv = def.split(" ");
    const n = defv.length;

    for (let i = 0; i < n; i++) {
      if (dat.i === i + 0.5) {
        defv = this.findCurveRedraw(dx1, dy1, defv, i, dat);
      }
    }

    const defs = defv.join(" ");
    return defs;
  }

  public findCurveRedraw(
    dx1: number,
    dy1: number,
    defv: any,
    i: number,
    dat: any
  ) {
    let j = 2;
    const type1 = defv[i];
    if (type1 === "a") {
      j = 6;
    }
    const type2 = defv[i + j + 1];

    if (type2 === "a") {
      defv = this.oneCurveRedraw(dx1, dy1, defv, i, j, dat);
    }

    return defv;
  }

  public oneCurveRedraw(
    dx1: number,
    dy1: number,
    defv: any,
    i: number,
    j: number,
    dat: any
  ) {
    const dxy: any = defv[i + j + 7].split(",");
    const dx = Number(dxy[0]);
    const dy = Number(dxy[1]);

    const ratioXY = this.calcs.ratioArc(dx, dy, dat.hx, dat.hy, dx1, dy1);
    const arcdir = this.calcs.arcDirection(
      dat.hx,
      dat.hy,
      dx1,
      dy1,
      defv[i + j + 6]
    );

    defv[i + j + 2] = ratioXY;
    defv[i + j + 3] = ratioXY;
    defv[i + j + 6] = arcdir;
    return defv;
  }
  public resizeDrawSize(dat: any, dx1: number, dy1: number) {
    const def = dat.dat.def;
    let defv = def.split(" ");
    const n = defv.length;

    let x1 = 0;
    let y1 = 0;

    let x3 = 0;
    let y3 = 0;

    for (let i = 0; i < n; i++) {
      let p = 0;

      let aa = [];

      if (i === dat.i) {
        x1 = -dx1;
        y1 = -dy1;
      }

      if (i === dat.i + 1) {
        x3 = dx1;
        y3 = dy1;
      }

      if (defv[i] === "M" || defv[i] === "l") {
        p = 1;
        defv[i + 1] = defv[i + 1] - x1 - x3;
        defv[i + 2] = defv[i + 2] - y1 - y3;
        x3 = 0;
        y3 = 0;
        x1 = 0;
        y1 = 0;
      }

      if (dat.i === i + 0.5) {
        defv = this.findCurveRedraw(dx1, dy1, defv, i, dat);
      }

      if (dat.i === i + 0.5) {
        let j = 3;
        const type1 = defv[i];
        if (type1 === "a") {
          j = 7;
        }
        const type2 = defv[i + j];
      }

      if (defv[i] === "a") {
        p = 2;

        aa = defv[i + 6].split(",");
        const x2 = Math.ceil(aa[0]) - x1 - x3;
        const y2 = Math.ceil(aa[1]) - y1 - y3;

        defv[i + 6] = x2 + "," + y2;
        x3 = 0;
        y3 = 0;
        x1 = 0;
        y1 = 0;
      }

      p = 0;
    }

    const defs = defv.join(" ");
    return defs;
  }
}
