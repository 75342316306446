
import { TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import LoginForm from "../components/login/loginForm.vue";
import { Format } from "../core/helpers/formatHelper";
import { AppsHelper } from "../core/helpers/appsHelper";
import store from "@shared/core/store";
//import store from "../core/store";

@Component({
  components: { LoginForm },
})
export default class LinkAccountPage extends Vue {
  settings: TenantSettings = {};
  token: string = "";
  logoUrl: string = "";
  urlOk: string = "";
  urlKo: string = "";
  error = "";

  async created() {
    this.token = this.$route.query.token as string;
    this.urlOk = this.$route.query.urlOk as string;
    this.urlKo = this.$route.query.urlKo as string;
    if (
      Format.IsNull(this.token) //||
      // Format.IsNull(this.urlKo) ||
      // Format.IsNull(this.urlOk)
    ) {
      this.error = "wrong query string";
      console.error("wrong query string");
    }

    this.settings = this.$store.state.settings;
    if (this.settings.Mainlogo !== null) {
      this.logoUrl = this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
  }

  onLogin(ok: any) {
    if (Format.IsNull(ok)) {
      return;
    }

    if (ok.ok === true) {
      this.loginOk();
      return;
    }
    this.loginKo();
  }

  loginOk() {
    this.callAndroidLoginCompleted(true);
    location.href = (this.urlOk ?? this.getTaykusUrl()) + "/link-account-response/true";
  }

  loginKo() {
    this.callAndroidLoginCompleted(false);    
    location.href = (this.urlOk ?? this.getTaykusUrl()) + "/link-account-response/false";
  }

  callAndroidLoginCompleted(ok: boolean) {
    if (AppsHelper.isAndroidWebView()) {
      var w = window as any;
      w.Android.onAccountLinked(ok);
      return;
    }
  }

  getTaykusUrl() : string {
    if (process.env.NODE_ENV === 'development') {
      return "//localhost:4000";
    }
    else {
      return "//www.taykus.com";
    }
  }
}
