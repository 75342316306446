
import {
  Component,
  Vue,
} from "@shared/node_modules/vue-property-decorator";

@Component
export default class Secure extends Vue {
  created() {}
}
