
import { CrudService } from "../core/services/crudService";
import { Settings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class TransbankRedirectPayment extends Vue {
  async mounted() {
    let url = `${Settings.HostName}/api/transbank/process-payment/${this.$route.params.id}?token_ws=${this.$route.query.token_ws}`;
    let data = await new CrudService().httpPost(url);
    if (data == null) {
      this.$router.push("/cz/ko");
      return;
    }

    if (data.ok === true) {
      this.$router.push("/cz/ok");
      return;
    }
    this.$router.push("/cz/ko");
  }
}
