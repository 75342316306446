
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";

type CheckboxValueType = boolean | "true" | "false";

@Component({})
export default class CheckBox extends Vue {
  @Prop({ default: false }) value!: CheckboxValueType;
  checked: boolean = false;

  @Watch("value")
  modelChanged(value: CheckboxValueType) {
    this.checked = value === true || value === "true" ? true : false;
  }
  mounted() {
    this.checked = this.value === true || this.value === "true" ? true : false;
  }

  @Emit("input")
  onChange(event: Event) {
    return (event.target as HTMLInputElement | null)?.value;
  }
}
