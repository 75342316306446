import { CrudService } from "./crudService";
import { Format } from "../helpers/formatHelper";

export class SettingsService {

    async loadConfig() {
        const url = `${Settings.HostName}/api/czconfigs/main-settings`;
        const res = await new CrudService().httpGet(url);

        if (res == null || !res.ok) {
            console.error("Not settings defined!");
            return null;
        }

        const data = res.data;
        const settings: TenantSettings = {
            Title: data.name,
            Description: data.d,
            Phone: data.p,
            Email: data.m,
            OpenHours: data.oh,
            Address: data.a,
            Facebook: data.f,
            Instagram: data.i,
            Twitter: data.t,
            ButtonsColor: data.bc,
            FooterColor: data.fc,
            FontFamily: data.ff,
            Gallery: data.g,
            FollowUsDescription: data.fu,
            CurrencySymbol: data.cs,
            DecimalSeparator: data.ds,
            TimeZone: data.tz,
            Culture: data.c,
            Mainlogo: data.logo,
            DateFormat: data.df,
            Uploads: Settings.HostName + data.u,
            RegisterOn: data.reg,
            HeaderImage: data.hi !== null && data.hi !== undefined ? Settings.HostName + "/" + data.u + "/" + data.hi : window.location.protocol + '//' + window.location.host + "/img/bredcrumb.png",
            HasShop: data.hasShop,
            PriceWithTaxes: data.priceWithTaxes,
            Coords: data.coords
        };

        this.setTitle(data.name);

        return settings;

    }

    setTitle(title: any) {
        window.document.title = Format.IsNull(title) ? "Taykus" : title;
    }
}

export abstract class Settings {

    static readonly Prefix = window.location.host + "_";
    static readonly HostName = Settings.GetHostName();
    private static GetHostName(): string {
        const host = window.location.hostname !== "localhost" ? window.location.host : "localhost:8819";
        return '//' + host;
    }
}

declare let process: { // do not delete!
    env: {
        NODE_ENV: string;
    };
};

export interface TenantSettings {
    Title?: string,
    Description?: string,
    FollowUsDescription?: string,
    Phone?: string,
    Email?: string,
    OpenHours?: string,
    Address?: string,
    Facebook?: string,
    Instagram?: string,
    Twitter?: string,
    ButtonsColor?: string,
    FooterColor?: string;
    FontFamily?: string,
    Gallery?: any[];
    HeaderImage?: string;

    CurrencySymbol?: string,
    DecimalSeparator?: string,
    TimeZone?: string,
    Culture?: string,
    Mainlogo?: string,
    Uploads?: string,
    DateFormat?: string;
    RegisterOn?: boolean;
    HasShop?: boolean;
    PriceWithTaxes?: boolean;
    Coords?: string;
}