import { BookingOrigin } from "../constants";
import store from "../store";

export class Format {

    static toLowerFirst(value: string) {
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    static formatCurrency(value: string | number) {
        if (value != "0" && (value == "" || value == null || value == undefined)) return "";
        const formated = this.formatNumber(value);
        return formated + store.state.settings.CurrencySymbol;
    }

    static formatNumber(value: string | number) {
        if (value != "0" && (value == "" || value == null || value == undefined)) return "";
        if (value.toString().indexOf('.') === -1) {
            return value;
        }
        let culture = store.state.settings.Culture;
        if (culture === "" || culture === null || culture === undefined) {
            culture = "en-US";
        }
        const formatter = new Intl.NumberFormat(culture, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });
        const s = formatter.format(Number(value));

        return s;
    }

    static removeNonPrintableChars(value: string) {
        if (Format.IsNull(value)) {
            return value;
        }
        return value.replace(/[^\x00-\x7FÀ-ÿ]/g, '');
    }

    static highLightText(words: any, query: string) {
        const iQuery = new RegExp(query, "ig");
        return words.toString().replace(iQuery, function (matchedTxt, a, b) {
            return ('<span class=\'post-creative-time\'>' + matchedTxt + '</span>');
        });
    }

    static IsNull(value: any) {
        return value === "" || value === null || value === undefined;
    }

    static getOrigin() {
        const userAgent = navigator.userAgent;
        const isIpone = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
            userAgent
        );
        const isAndroid = /Android.*wv/i.test(userAgent);

        if (isIpone) {
            return BookingOrigin.CZ_iOS;
        }
        if (isAndroid) {
            return BookingOrigin.CZ_Android;
        }
        return BookingOrigin.CZ_Web;
    }

    static toCamelCase(value: any) {
        if (value) {
            const words = value.split(' ');
            const camelCaseWords = words.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });
            return camelCaseWords.join(' '); // Join with spaces
        }
        return value;
    }

    static convertKeysToUpperCase(objArray) {
        return objArray.map(obj => {
            const newObj = {};
            Object.keys(obj).forEach(key => {
                const newKey = key.charAt(0).toUpperCase() + key.slice(1);
                newObj[newKey] = obj[key];
            });
            return newObj;
        });
    }

    static convertKeysToLowerCase(objArray) {
        return objArray.map(obj => {
            const newObj = {};
            Object.keys(obj).forEach(key => {
                const newKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObj[newKey] = obj[key];
            });
            return newObj;
        });
    }

    static formatFullName(firstname: string, ...lastname: string[]) {
        return [firstname, ...lastname].join(" ").trim();
    }
}