import { CrudService } from "./crudService";
import { Settings } from "./configService";

export class TicketService {
    crudService: CrudService = new CrudService();
    basePath: string = `${Settings.HostName}/api`;

    async downloadTicketPdf(token: string, callback?: Function) {
        const url: string = `${this.basePath}/publictickets/download-ticket/${token}`;
        await this.crudService.httpGet(url, null, callback);
    }
}