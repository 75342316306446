
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import Checkbox from "../../components/form/checkBox.vue";

@Component({
  components: { Checkbox },
})
export default class PubliCheck extends Vue {
  @Prop({default: false}) value!: boolean;
  @Emit("input")
  onChange(val: any) {
    return val;
  }
}
