import { LocalStorage } from "../../../shared/core/localStorage";
import { Locales } from "../constants";

export class LocaleHelper {

    // take culture for locale translations. All cultures that start with es are translated into es-ES
    // If user selects culture, return selected
    static toLocaleCulture(culture: string): string {
        const storedCulture = LocalStorage.get("lcl");
        culture = (storedCulture === null || storedCulture === undefined) ? culture : storedCulture;
        if (culture.split("-")[0] === "es") {
            if (culture === "es-ES") {
                return culture;
            }
            return "es-MX";
        }
        return culture;
    }

    public static defaultFallBack(culture: any) {
        const storedCulture = LocalStorage.get("lcl");
        culture = (storedCulture === null || storedCulture === undefined) ? culture : storedCulture;
        if (culture.indexOf("es-") !== -1) {
            return Locales.esES;
        }
        return Locales.enUS;
    }

    /**
     * Converts long locale codes (with country as ISO 3166) to short locale codes (ISO 639-1).
     * @description e.g. "es-ES" converts to "es"
     * @example toShortLocaleCode("es-ES")
     * @param locale Long locale code
     * @returns Short locale code
     */
    public static toShortLocaleCode(locale: string): string {
        const parts = locale.split("-");
        if (parts.length > 0) {
            return parts[0];
        }
        return locale;
    }
}
