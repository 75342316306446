import { render, staticRenderFns } from "./myBookingsPage.vue?vue&type=template&id=46700adc&scoped=true&"
import script from "./myBookingsPage.vue?vue&type=script&lang=ts&"
export * from "./myBookingsPage.vue?vue&type=script&lang=ts&"
import style0 from "./myBookingsPage.vue?vue&type=style&index=0&id=46700adc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../padel/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46700adc",
  null
  
)

export default component.exports