
import { Component, Vue, Prop } from "vue-property-decorator";
import { Format } from "../../core/helpers/formatHelper";

@Component
export default class InputPassword extends Vue {
  @Prop() error!: string;
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop() required!: boolean;

  type: string = "password";
  mounted() {}
  isNull(value: string) {
    return Format.IsNull(value);
  }
}
