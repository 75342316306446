import Vue from '@shared/node_modules/vue';
import VueI18n from '@shared/node_modules/vue-i18n';
import { messages, defaultLocale } from "./locales/localization";
import { LocaleHelper } from "@shared/core/helpers/localeHelper";
import { router } from './router/router';
import store from '@shared/core/store';
import App from './App.vue';

import './css/bootstrap.css';
import './css/fonts.css';
import './css/style.css';
import './js/script.js';
import { INIT } from './js/script.js';

import '@shared/components/tailwind-components.css';
import { Format } from '@shared/core/helpers/formatHelper';

////////////////////////////////////////////////////////////////////////////////////
// GLOBAL CONFIG ///////////////////////////////////////////////////////////////////
Vue.prototype.$public = "/public/assets";
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

Vue.use(VueI18n);

store.dispatch("loadSettings").then((result) => {
  if (result === undefined || result === null) {
    window.location.href = "/404-page.html";
    return;
  }

  let culture = store.state.settings.Culture;
  if (culture === "" || culture === null || culture === undefined) {
    culture = "en-US";
  }
  Vue.prototype.$culture = culture;

  Vue.prototype.$t = function (...args: any[]) {
    if (i18n === undefined) {
      return args[0];
    }

    const primaryLocale = i18n.t(args);
    if (!Format.IsNull(primaryLocale)) {
      return primaryLocale;
    }

    const fallbackLocale = i18n.t(args[0], i18n.fallbackLocale, messages);
    if (!Format.IsNull(fallbackLocale)) {
      return fallbackLocale;
    }

    return args[0];
  };

  const i18n: any = new VueI18n({
    locale: LocaleHelper.toLocaleCulture(culture),
    fallbackLocale: LocaleHelper.defaultFallBack(culture),
    messages,
    silentTranslationWarn: true
  });

  new Vue({
    router: router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
  INIT();


});


