import { EditPathMath } from "./EditPathMath";
import { Calcs } from "./Calcs";
export class DrawPaths {
  public fontSize = 20;
  public scale = 1.85;
  public editPathMath: EditPathMath = new EditPathMath();
  public cals: Calcs = new Calcs();

  public getGroupsPath(pathss: any, type?: string): any[] {
    const draws: any = [];
    const paths: any = pathss.paths;
    if (type === undefined) {
      type = "data";
    }
    const data = pathss[type];

    data.forEach((item: any) => {
      const path = paths[item.ref];
      const draw = this.addPath(item, path, draws.length, type);
      draws.push(draw);
    });

    return draws;
  }

  public addPathToGroup(draws: any[], item: any, path: any, type?: string) {
    const ind = draws.length;
    const draw = this.addPath(item, path, ind, type);
    draws.push(draw);
    return draws;
  }

  public addPath(item: any, path: any, ind: number, type?: string) {
    let def = path.d;
    let w1: any = "";
    let h1: any = "";
    let x1: any = "";
    let y1: any = "";
    const trans =
      " translate(" +
      item.trans[0] +
      "," +
      item.trans[1] +
      ") rotate(" +
      item.rot +
      ") ";

    if (path.type === "rect") {
      const dd = def.split(" ");
      w1 = dd[0];
      h1 = dd[1];
      def = "";
      x1 = -(w1 / 2);
      y1 = -(h1 / 2);
    }
    let textTrans = "";

    let section = -1;
    if (item.section !== undefined) {
      section = item.section;
    }

    let sectionBlocked = -1;
    if (item.section !== undefined) {
      sectionBlocked = item.section;
    }
    const draw: any = {
      type: path.type,
      label: item.label,
      x: x1,
      y: y1,
      width: w1,
      height: h1,
      def,
      transform: trans,
      x0: item.trans[0],
      y0: item.trans[1],
      isActive: false,
      isDragon: false,
      rot: item.rot,
      textTrans,
      section,
      sectionBlocked,
      ind,
      op: type,
      id: path.id,
      placeId: item.id,
      row: item.row,
      col: item.col
    };

    if (path.type === "path") {
      // textTrans = this.textCenter(path.d, draw);
      const aa = this.getCentroid1(draw);

      const defv = def.split(" ");
      defv[1] = Number(defv[1]) - aa[0];
      defv[2] = Number(defv[2]) - aa[1];

      def = defv.join(" ");

      draw.def = def;
      const bb = this.getCentroid1(draw);
      w1 = bb[0];
      h1 = bb[1] + this.fontSize / 4;
      textTrans = " translate(" + w1 + "," + h1 + ") ";
      draw.textTrans = textTrans;
    }

    return draw;
  }

  public getCentroid1(path: any) {
    let rx1 = 0;
    let ry1 = 0;
    const dat = path;

    this.editPathMath = new EditPathMath();
    const getFrame = this.editPathMath.setEditDraw(dat);

    const frameA = getFrame.vedit;

    const nn = frameA.length;
    let sx1 = 0;
    let sy1 = 0;
    for (let i = 0; i < nn; i++) {
      sx1 = sx1 + frameA[i].x1;
      sy1 = sy1 + frameA[i].y1;
    }
    rx1 = sx1 / nn;
    ry1 = sy1 / nn;

    return [rx1, ry1];
  }
  public textCenter(def: any, path?: any) {
    let w1 = 0;
    let h1 = 0;

    if (path !== undefined) {
      const aa = this.getCentroid1(path);
      w1 = aa[0];
      h1 = aa[1] + this.fontSize / 4;
    }

    const textTrans = " translate(" + w1 + "," + h1 + ") ";
    return textTrans;
  }
  public getCentroid(def: any): number[] {
    const defv = def.split(" ");
    const n = defv.length;

    let p = false;
    let x1 = 0;
    let y1 = 0;
    let x2 = 0;
    let y2 = 0;
    let j = 0;
    const vedit: any = [];
    let type = "";
    for (let i = 0; i < n; i++) {
      const aa: any = [];
      if (defv[i] === "M" || defv[i] === "l") {
        type = defv[i];
        p = true;

        aa[0] = Math.ceil(defv[i + 1]);
        aa[1] = Math.ceil(defv[i + 2]);
        def = aa;
      }

      if (defv[i] === "a") {
        type = defv[i];
        const vv = defv[i + 6].split(",");
        aa[0] = Math.ceil(vv[0]);
        aa[1] = Math.ceil(vv[1]);

        p = true;
      }

      if (p) {
        x1 = x1 + aa[0];
        y1 = y1 + aa[1];
        x2 = x2 + x1;
        y2 = y2 + y2;

        vedit[j] = { x: aa[0], y: aa[1], i: j, type, def };
        j++;
      }
      p = false;
    }

    const m = vedit.length - 1;
    for (let i = 0; i < m; i++) {
      const ve1 = vedit[i];

      let i2 = i + 1;
      if (i === n - 1) {
        i2 = 0;
      }
      const ve2 = vedit[i2];

      const ve = Object.assign({}, ve1);
      const dx = (ve2.x - ve1.x) / 2;
      const dy = (ve2.y - ve1.y) / 2;
      let x0 = ve1.x + dx;
      let y0 = ve1.y + dy;

      let h1 = 0;
      if (ve2.type === "a") {
        const d = Math.sqrt(dx * dx + dy * dy);
        let r = ve2.def[2];
        const an1 = Math.abs(Math.atan(dx / dy));

        let an2 = 1.5707963267949;
        if (r > d) {
          an2 = Math.abs(Math.asin(d / r));
        } else {
          r = d;
          ve2.def[2] = r;
        }
        h1 = r * (1 - Math.cos(an2));
        const hx = h1 * Math.cos(an1);
        const hy = h1 * Math.sin(an1);
        x0 = x0 - hx;
        y0 = y0 - hy;
      }
      ve.i = ve.i + 0.5;
      x1 = x1 + x0;
      y1 = y1 + y0;

      x2 = x2 + x1;
      y2 = y2 + y1;
    }

    x1 = x2 / (j * 2);
    y1 = y2 / (j * 2);

    return [x1, y1];
  }

  public getParams(def: string): any {
    const defs = def.replace("a", "|a").replace("l", "|l");
    const defv = defs.split("|");

    const xy1 = defv[0].split(" ");
    const x = Number(xy1[1]);
    const y = Number(xy1[2]);
    let params = {};
    if (defv.length > 1) {
      const arc = defv[1].split(" ");

      if (arc[0] === "a") {
        const r = Number(arc[1]);
        const xy2 = arc[6].split(",");
        const x2 = Number(xy2[0]);
        const y2 = Number(xy2[1]);
        const dx = x2 - 0;
        const dy = y2 - 0;

        const d = Math.hypot(dx, dy);
        const d2 = d / 2;
        const an1 = Math.atan(y2 / x2);

        let an2 = Math.PI / 2;
        if (r > d) {
          an2 = Math.asin(d / r);
        }

        const an4 = Math.asin(d2 / r);
        const h = r * (1 - Math.cos(an4));
        const ap = an4 * 2;
        const p = 2 * Math.PI * r;
        const l = ap * r;
        params = { h, d, ap, an1, an2, r, p, l, dx, dy, arc };
      }
    }

    const co = { x, y, params };

    return co;
  }

  public getCorner(def: any): any {
    const defv = def.split(" ");
    const n = defv.length;
    const co = { w: 0, n: 0, e: 0, s: 0 };

    let p = false;
    let x1 = 0;
    let y1 = 0;
    let x2 = 0;
    let y2 = 0;
    let j = 0;
    const vedit: any = [];
    let type = "";
    for (let i = 0; i < n; i++) {
      const aa: any = [];
      if (defv[i] === "M" || defv[i] === "l") {
        type = defv[i];
        p = true;
        aa[0] = Math.ceil(defv[i + 1]);
        aa[1] = Math.ceil(defv[i + 2]);
        def = aa;
      }

      if (defv[i] === "a") {
        type = defv[i];
        const vv = defv[i + 6].split(",");
        aa[0] = Math.ceil(vv[0]);
        aa[1] = Math.ceil(vv[1]);

        p = true;
      }

      if (p) {
        x1 = x1 + aa[0];
        y1 = y1 + aa[1];

        if (x1 < co.w) {
          co.w = x1;
        }
        if (y1 < co.n) {
          co.n = y1;
        }
        if (x1 > co.e) {
          co.e = x1;
        }
        if (y1 > co.s) {
          co.s = y1;
        }

        x2 = x2 + x1;
        y2 = y2 + y2;

        vedit[j] = { x: aa[0], y: aa[1], i: j, type, def };
        j++;
      }
      p = false;
    }

    const m = vedit.length - 1;
    for (let i = 0; i < m; i++) {
      const ve1 = vedit[i];

      let i2 = i + 1;
      if (i === n - 1) {
        i2 = 0;
      }
      const ve2 = vedit[i2];

      const ve = Object.assign({}, ve1);
      const dx = (ve2.x - ve1.x) / 2;
      const dy = (ve2.y - ve1.y) / 2;
      let x0 = ve1.x + dx;
      let y0 = ve1.y + dy;

      let h1 = 0;
      if (ve2.type === "a") {
        const d = Math.sqrt(dx * dx + dy * dy);
        let r = ve2.def[2];
        const an1 = Math.abs(Math.atan(dx / dy));

        let an2 = 1.5707963267949;
        if (r > d) {
          an2 = Math.abs(Math.asin(d / r));
        } else {
          r = d;
          ve2.def[2] = r;
        }
        h1 = r * (1 - Math.cos(an2));
        const hx = h1 * Math.cos(an1);
        const hy = h1 * Math.sin(an1);
        x0 = x0 - hx;
        y0 = y0 - hy;
      }
      ve.i = ve.i + 0.5;
      x1 = x1 + x0;
      y1 = y1 + y0;

      x2 = x2 + x1;
      y2 = y2 + y1;
    }

    x1 = x2 / (j * 2);
    y1 = y2 / (j * 2);
    return co;
  }
}
