
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateHelper } from "../../core/helpers/dateHelper";
import { Format } from "../../core/helpers/formatHelper";

@Component
export default class InputDate extends Vue {
  @Prop() cssClass!: string;
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop() error!: string;
  @Prop() required!: boolean;
  valueFormatted: string = "";

  mounted() {
    if (Format.IsNull(this.value)) {
      return;
    }
    const d = DateHelper.toDate(this.value, DateHelper.systemFormat);
    if (d == undefined) {
      console.error("unable to parse date " + this.value);
      return;
    }

    let m = (d.getMonth() + 1).toString();
    if (d.getMonth() + 1 < 10) {
      m = "0" + m;
    }

    let day = d.getDate().toString();
    if (d.getDate() < 10) {
      day = "0" + day;
    }
    this.valueFormatted = d.getFullYear() + "-" + m + "-" + day; // todo: datepicker taking tenant culture format
  }

  isNull(value: string) {
    return Format.IsNull(value);
  }
}
