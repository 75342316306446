
import { CrudService } from "../core/services/crudService";
import { Settings, TenantSettings } from "../core/services/configService";
import Vue from "vue";
import Component from "vue-class-component";
import { LocalStorage } from "../core/localStorage";
import { Format } from "../core/helpers/formatHelper";
import BookingSummary from "../components/bookingSummary/bookingSummary.vue";
import TriggerAnchor from "../components/triggerAnchor/triggerAnchor.vue";
import { BookingTeam } from "../core/constants";

@Component({
  components: {
    TriggerAnchor,
    BookingSummary,
  },
})
export default class BookingResultsPage extends Vue {
  settings: TenantSettings = {};
  error: string = "";
  booking: any = null;
  addPlayerPopup: boolean = false;
  players: any[] = [];
  owner: any | null = null;
  editAllowed: boolean = false;
  refreshPlayers: number = 1;
  isIn: boolean = false;

  async created() {
    let sid = LocalStorage.getWithTimeout("cc");
    if (Format.IsNull(sid)) {
      this.$store.commit("openLogin", { backUrl: this.$route.path });
      return;
    }

    this.owner = JSON.parse(sid);

    const query = this.$route.params.bookingId;
    if (Format.IsNull(query)) {
      this.$router.push("bookingtypes");
      return;
    }

    await this.loadBooking();
  }

  async loadBooking() {
    const url = `${Settings.HostName}/api/publicbookings/info/${this.$route.params.bookingId}`;
    const res = await new CrudService().httpGet(url);
    if (res == null) {
      this.$store.commit("openGlobalError", this.$t("crash"));
      return;
    }

    if (res.ok === false) {
      this.$store.commit("openGlobalError", res.errorMsg);
      return;
    }

    this.booking = res.model;
    if (!this.booking.isPast) {
      return this.$router.push("/booking-join/" + this.booking.id);
    }

    this.booking.date = res.model.dtStart;
    this.booking.resources = res.model.resourceName;
    this.players = res.model.players;
    const parti = this.players.find((y) => y.customerId === this.owner.id);
    if (parti !== undefined) {
      this.isIn = true;
    }
    if (parti === undefined || this.booking.results.team1.length !== 0) {
      this.editAllowed = false;
    }
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  getPlayersTeam1() {
    console.log(
      this.booking.players
        .filter((y) => y.team == BookingTeam.Team1)
        .map((y) => y.name)
        .join(" / ")
    );
    return this.booking.players
      .filter((y) => y.team == BookingTeam.Team1)
      .map((y) => y.name)
      .join(" / ");
  }

  getPlayersTeam2() {
    return this.booking.players
      .filter((y) => y.team == BookingTeam.Team2)
      .map((y) => y.name)
      .join(" / ");
  }

  async setTeam(id: number, team: BookingTeam) {
    if (!this.isIn) {
      return;
    }

    const url = `${Settings.HostName}/api/publicbookings/update-team`;
    const res = await new CrudService().httpPut(url, {
      id: id,
      team: team,
    });

    if (Format.IsNull(res)) {
      this.$store.commit("openGlobalError", $t("crash"));
      return;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    let p = this.players.find((y) => y.id == id);
    p.team = team;
    this.refreshPlayers++;
  }

  async saveResults() {
    var results: any = {
      bookingId: this.booking.id,
      team1: [],
      team2: [],
    };

    for (let i = 0; i < this.booking.results.nSets; i++) {
      var domTeam1: any = this.$refs[`team_1_${i}`];
      if (
        domTeam1 !== null &&
        domTeam1.length != 0 &&
        !Format.IsNull(domTeam1[0].value)
      ) {
        results.team1.push(parseInt(domTeam1[0].value));
      }

      var domTeam2: any = this.$refs[`team_2_${i}`];
      if (
        domTeam2 !== null &&
        domTeam2.length != 0 &&
        !Format.IsNull(domTeam2[0].value)
      ) {
        results.team2.push(parseInt(domTeam2[0].value));
      }
    }

    if (
      this.booking.results.nSets === 5 &&
      (results.team1.length < 3 || results.team2.length < 3)
    ) {
      this.error = this.$t("Please fill at least 3 sets").toString();
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    if (results.team1.length === 0 || results.team2.length === 0) {
      this.error = this.$t("Please fill at least one result").toString();
      this.$store.commit("openGlobalError", this.error);
      return;
    }

    const url = `${Settings.HostName}/api/publicbookings/save-results`;
    const res = await new CrudService().httpPost(url, results);

    if (Format.IsNull(res)) {
      this.$store.commit("openGlobalError", $t("crash"));
      return;
    }

    if (res.ok === false) {
      this.error = res.errorMsg;
      this.$store.commit("openGlobalError", this.error);
      return;
    }
    this.booking.results.team1 = res.model.team1;
    this.booking.results.team2 = res.model.team2;
    this.editAllowed = false;
  }

  getTeam(team: BookingTeam) {
    return team === BookingTeam.Team1
      ? this.$t("Team") + " " + 1
      : this.$t("Team") + " " + 2;
  }

  getImagePath(img: string) {
    if (img === null || img === undefined || img === "") {
      return this.settings.Uploads + "/" + this.settings.Mainlogo;
    }
    return this.settings.Uploads + "/" + img;
  }
}
