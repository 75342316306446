
import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { LocalStorage } from "../../../shared/core/localStorage";
import { DateHelper } from "../../../shared/core/helpers/dateHelper";

@Component({
  components: {},
})
export default class LevelsPage extends Vue {
  data: any = null;
  errorMsg: string = "";

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }

    await this.loadData(cid);
  }

  async loadData(customerId: number) {
    let url = `${Settings.HostName}/api/publicbookings/levels/${customerId}`;
    let data = await new CrudService().httpGet(url);
    if (data == null || data.ok === false) {
      this.errorMsg = data.errorMsg;
      console.error("Crash getting my comms");
      return;
    }

    this.data = data.model;
  }

  showEmail(id: any) {
    this.$router.push(`/customer-comms/emails/${id}`);
  }

  getDate(date: string) {
    return DateHelper.toCultureString(date);
  }
}
