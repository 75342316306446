
import { CrudService } from "../../../shared/core/services/crudService";
import { Settings } from "../../../shared/core/services/configService";
import Vue from "../../../shared/node_modules/vue";
import Component from "../../../shared/node_modules/vue-class-component";
import { LocalStorage } from "../../../shared/core/localStorage";

@Component
export default class TrainingsPage extends Vue {
  data: any = null;

  async mounted() {
    let cid = 0;
    try {
      cid = JSON.parse(LocalStorage.getWithTimeout("cc")).id;
    } catch {
      console.error("not customer logged found");
    }
    let url = `${Settings.HostName}/api/publicteams/trainings`;
    let data = await new CrudService().httpPost(url, {
      customerId: cid,
    });

    if (data == null || data.ok === false) {
      console.error("Crash getting trainings");
      return;
    }
    this.data = data.model;
  }
}
