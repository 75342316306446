
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Accordion extends Vue {
  @Prop() title!: string;
  @Prop() expanded!: boolean;

  mounted() {
    if (this.expanded === true) {
      this.click();
    }
  }

  click() {
    const dom: any = this.$refs.accordion;
    dom.classList.toggle("active");
    dom.classList.toggle("collapsed");
    var panel = dom.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}
